import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { SendInviteMessages } from "tp/site-admin/startpage/messages";
import { SendInviteTable } from "tp/site-admin/onboarding/steps/5-send-invite/SendInviteTable";
import { WizardStepWrapper } from "./WizardStepWrapper";
import { useWizardCompletionData, WizardStepProps } from "./Wizard";
import { Box } from "@mui/material";

export function SendInviteStep(props: WizardStepProps): React.ReactElement {
    const { isActive, setCanContinue, onClose } = props;

    useEffect(() => setCanContinue(true), [setCanContinue]);

    return (
        <WizardStepWrapper
            title={<FormattedMessage {...SendInviteMessages.Title} />}
            isActive={isActive}
            onClose={onClose}
        >
            <Box sx={{
                // This is an override of the global css selector 'input[type=checkbox]' defined in 'WebSite/_css/Global.css'
                "& input[type=checkbox]":
                {
                    width: "100%",
                    height: "100%",
                }
            }}>
                <SendInviteTable onSelect={(emails) => useWizardCompletionData({ emails })} />
            </Box>
        </WizardStepWrapper>
    );
}