import { HttpClient, HttpRequest } from "tp/views/common/HttpClient";

let client: HttpClient = null;

export const initialize = (url: string): void => {
    client = new HttpClient(`${url}/security`);
};

export const resetPassword = (email: string): Promise<void> => {
    return client.fetch(HttpRequest.postJson("/resetPassword", { email }));
};
