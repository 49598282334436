import * as React from "react";
import ReactSelectSelect, { Props as SelectProps, StylesConfig } from "react-select";
import { selectTemplate, SelectOption, GenericSelectOption, SelectStateOption } from "../select-styles";
import { findIndex } from "../../../shared/common/linq";
import { Black, Gray1, Gray2 } from "../../../shared/common-design/colors";
import { FormControl, FormHelperText, InputLabel, Select as MuiSelect } from "@mui/material";

export interface MultiSelectWithDeleteProps extends Pick<SelectProps<SelectOption, true>, "placeholder" | "onChange"> {
    values: number[];
    options: SelectOption[];
    maxInputHeight?: number;
    fontSize?: string;
}

interface SimpleSelectProps<TValue> extends Pick<SelectProps<GenericSelectOption<TValue>, false>, "isDisabled" | "onChange"> {
    value: TValue;
    options: GenericSelectOption<TValue>[];
}

const commonStyle = (fontSize?: string): StylesConfig<SelectStateOption> =>
    ({
        //...baseStyle,
        container: (provided) => ({
            ...provided,
            display: "block"
        }),
        control: (provided) => ({
            ...provided,
            borderRadius: 3,
            borderColor: Gray1
        }),
        multiValue: (provided, { data }) => ({
            ...provided,
            borderRadius: 3,
            padding: 3,
            backgroundColor: data.state ? Gray2 : Gray1,
            color: data.state ? Black : Gray2
        }),
        multiValueLabel: (provided, { data }) => ({
            ...provided,
            color: data.state ? Black : Gray2,
            fontSize: fontSize ?? provided.fontSize,
        }),
    });

export function GenericSelect<TValue>(props: SimpleSelectProps<TValue>): React.ReactElement {
    const { value, options, isDisabled, onChange } = props;

    return (
        <ReactSelectSelect
            {...selectTemplate}
            value={options.find(o => o.value === value)}
            styles={commonStyle()}
            options={options}
            onChange={onChange}
            isDisabled={isDisabled}
        />
    );
}

export const SimpleSelect = GenericSelect as (p: SimpleSelectProps<number>) => React.ReactElement;

export function MultiSelectWithDelete(props: MultiSelectWithDeleteProps): React.ReactElement {
    const { placeholder, onChange, values, options, maxInputHeight, fontSize } = props;

    const value = options.filter(o =>
        findIndex(values, value => value === o.value) >= 0
    );

    // Workaround inspired by https://github.com/JedWatson/react-select/issues/1322
    let styles = commonStyle(fontSize);
    if (maxInputHeight !== undefined)
    {
        styles = {
            ...styles,
            valueContainer: (provided) => ({
                ...provided,
                maxHeight: maxInputHeight + "px",
                overflowY: "auto",
            }),
            indicatorsContainer: (provided) => ({
                ...provided,
                maxHeight: maxInputHeight + "px",
            })
        };
    }

    return (
        <ReactSelectSelect
            {...selectTemplate}
            styles={styles}
            isMulti
            closeMenuOnSelect={false}
            isClearable={false}
            hideSelectedOptions={true}
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            value={value}
            noOptionsMessage={(): string => ""}
        />
    );
}

export type SelectInputProps = Pick<React.ComponentProps<typeof MuiSelect>, "children" | "onChange" | "value" | "label">
    & Pick<React.ComponentProps<typeof FormControl>, "error" | "disabled" | "sx">
    & { helperText?: React.ReactNode };

export function SelectInput(props: SelectInputProps): React.ReactElement {
    const fontComputedPixelHeight = 23;
    const desiredPixelHeight = 34.25;
    const verticalPadding = (desiredPixelHeight - fontComputedPixelHeight) / 2;
    return (
        <FormControl
            sx={props.sx}
            fullWidth
            variant="outlined"
            size="small"
            error={props.error}
            disabled={props.disabled}>
            <InputLabel shrink={props.value ? true : undefined}>{props.label}</InputLabel>
            <MuiSelect
                label={props.label}
                value={props.value}
                onChange={props.onChange}
                SelectDisplayProps={{ style: { fontSize: "12px", paddingTop: verticalPadding, paddingBottom: verticalPadding } }}>
                {props.children}
            </MuiSelect>
            <FormHelperText sx={{ margin: "auto", fontSize: "0.65rem" }}>{props.helperText}</FormHelperText>
        </FormControl>);
}
