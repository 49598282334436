import React, { ReactElement } from "react";
import { TableCell, TableCellProps, Tooltip } from "@mui/material";
import { DateTime } from "luxon";

export interface DateCellProps extends Omit<TableCellProps, "children"> {
    date?: DateTime
    dateTimeFormat: string
    shortDateFormat: string
}

export function DateCell(props: DateCellProps): ReactElement {
    const { date, dateTimeFormat, shortDateFormat, sx, ...restProps } = props;
    return (
        <TableCell sx={{ whiteSpace: "nowrap", ...sx }} {...restProps}>
            {date && <Tooltip title={date.toFormat(dateTimeFormat)}><p>{date.toFormat(shortDateFormat)}</p></Tooltip>}
        </TableCell>);
}