import { MessageDescriptor } from "react-intl";
import { REQUEST_ERROR } from "../actions";

export interface IError {
    text: string;
    json: TypedException;
    message: MessageDescriptor;
}

export interface TypedException {
    type: string;
    values: Record<string, string>;
    errorCategory: string;
}

export const ERROR_SEEN = "ERROR_SEEN";

export function alerts(
    state: IError[],
    action: { 
        type: string, 
        message: string, 
        jsonError: TypedException, 
        textError: string,
        userMessage: MessageDescriptor
    }): IError[] {

    if (state === undefined) {
        return [];
    }

    if (action.type === ERROR_SEEN) {
        return state.slice(0, state.length - 1);
    } else if (action.message === REQUEST_ERROR) {
        return state.concat([{ json: action.jsonError, text: action.textError, message: action.userMessage }]);
    }

    return state;
}
