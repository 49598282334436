import React, { ReactElement, useState } from "react";
import { ButtonProps, Checkbox, FormControlLabel } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Common } from "tp/views/common/messages";
import { DigitalSigningLogState, useDigSigLogSelector } from "../../redux";
import { validateEmail, validatePersonName } from "../../../../../common/validation";
import { useDispatch } from "react-redux";
import { incrementStep } from "../../actions";
import { wizardIntl } from "../DigitalSigningWizard";
import { ConfirmButton } from "tp/shared/common/components/buttons/ActionButtons";

export function ConfirmAndSend(): ReactElement {
    const [checked, setChecked] = useState(false);
    const { employeeInfo, employerInfo } = useDigSigLogSelector(state => state.digitalSigningLog);
    const dispatch = useDispatch();

    const handleSendClicked = () => {
        dispatch(incrementStep());
        // Contract is sent in next step
    };

    // Disable button if not checked and not all fields are validated
    const empValidated = (info: DigitalSigningLogState["employeeInfo"] | DigitalSigningLogState["employeeInfo"]): boolean =>
        validatePersonName(info.name) && validateEmail(info.email);
    const sendButtonDisabled = !checked || !empValidated(employeeInfo) || !empValidated(employerInfo);

    const centerContent = {
        display: "flex",
        justifyContent: "center"
    };
   
    return (
        <>
            <div style={centerContent}>
                <FormControlLabel
                    control={<Checkbox color="primary" checked={checked} onChange={e => setChecked(e.target.checked)} />}
                    label={<FormattedMessage id={wizardIntl("ConfirmChoices")} />}
                />
            </div>
            <br />
            <div style={centerContent}>
                <SendButton disabled={sendButtonDisabled} onClick={handleSendClicked} />
            </div>
        </>
    );
}

function SendButton(props: ButtonProps) {
    return (
        <ConfirmButton {...props}>
            <FormattedMessage {...Common.send} />
        </ConfirmButton>
    );
}
