import { Action } from "redux";
import { OnboardingState } from "./onboardingState";
import { ActionType } from "./actions";
import { RowType } from "../../steps/4-staff-table/models/RowType";

export interface OnboardingAction extends Action<ActionType> {
    stafflist?: {
        unfilteredStaff: RowType[],
        staff: OnboardingState["staff"]
    },
    collectiveAgreementOptions?: OnboardingState["collectiveAgreementOptions"],
    staffTableFields?: string[],
}

export function onboardingReducer(state: OnboardingState = null, action: OnboardingAction): OnboardingState {
    switch (action.type) {
        case ActionType.REFRESH_COLLECTIVE_AGREEMENT_OPTIONS:
        case ActionType.SET_COLLECTIVE_AGREEMENT_OPTIONS:
            state.collectiveAgreementOptions = action.collectiveAgreementOptions;
            return state;
        case ActionType.SET_STAFF_TABLE_FIELDS:
            state.staffTableFields = action.staffTableFields;
            return state;
        case ActionType.SET_STAFF_LIST: {
            state.unfilteredStaff = action.stafflist.unfilteredStaff;
            state.staff = action.stafflist.staff;
            return state;
        }
        default:
            return state ?? {
                staff: [],
                unfilteredStaff: [],
                collectiveAgreementOptions: [],
                staffTableFields: [],
            };
    }
}