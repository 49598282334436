import * as React from "react";
import "./LoadingClock.scss";
import * as classnames from "classnames";

export function LoadingClock(props: { disableDiv?: boolean, size?: number }): React.ReactElement {
    const width = props.size === undefined ? "250px" : `${props.size}px`;
    const basicStyle = {
        width: width,
        height: width,
        margin: "auto"
    };

    const classes = classnames({ loadingClockDiv: !props.disableDiv });

    return (
        <div style={basicStyle} className={classes}>
            <svg id="clock" width="90%" height="90%" viewBox="-50 -60 100 120">
                <defs>
                    <g id="clockface">
                        <circle cx="0" cy="0" r="49" />
                    </g>
                    <g id="hand">
                        <line x1="0" y1="0" x2="0" y2="-40" />
                        <circle cx="0" cy="0" r="2.5" />
                    </g>
                </defs>
                <use xlinkHref="#clockface" />
                <g id="offset-hours">
                    <use id="hour-hand" xlinkHref="#hand" />
                </g>
                <g id="offset-minutes">
                    <use id="minute-hand" xlinkHref="#hand" />
                </g>
            </svg>
        </div>
    );
}
