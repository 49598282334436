import React, { useState } from "react";
import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { MultiValue } from "react-select";
import { MultiSelectWithDelete, MultiSelectWithDeleteProps } from "tp/views/common-forms/components/Selects";
import { SelectOption, SelectStateOption } from "tp/views/common-forms/select-styles";
import { PlaceHolders } from "tp/views/common/messages";


export interface PersonMultiSelectProps extends Pick<MultiSelectWithDeleteProps, "maxInputHeight" | "fontSize"> {
    selected: number[],
    persons: { ref: number, name: string, emplno: string }[],
    onChange: ( personRefs: number[] ) => void,
}

export function PersonMultiSelect(props: PersonMultiSelectProps): ReactElement {
    const { selected, persons, onChange, ...rest } = props;

    const [localSelected, setLocalSelected] = useState(selected); 

    const options: SelectStateOption[] = persons
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(p => ({
            value: p.ref,
            label: `[${p.emplno}] ${p.name}`,
            state: true
        }));

    const handleChange = (values: MultiValue<SelectOption>) => {
        const newSelection = values.map(v => v.value);
        setLocalSelected(newSelection);
        onChange(newSelection);
    };

    return (
        <MultiSelectWithDelete
            {...rest}
            placeholder={<FormattedMessage {...PlaceHolders.selectPerson}></FormattedMessage>}
            values={localSelected}
            options={options}
            onChange={handleChange}
        />
    );
}
