import {
    DialogContent,
    MobileStepper,
    CircularProgress,
    Stack,
    Alert,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import PrimaryButton from "tp/shared/common/components/buttons/PrimaryButton";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Common } from "tp/views/common/messages";
import WizardDialog from "./WizardDialog";
import SecondaryButton from "tp/shared/common/components/buttons/SecondaryButton";
import { AddPersonsMessages } from "../../messages";
import { components } from "tp/shared/timeplan-api-schema";
import { staffPersonsPost, staffPersonsPostError } from "tp/shared/timeplan-api-client";
import { buttonBorderRadius } from "tp/views/common-design/borders";

export type StepperComponent = () => React.ReactElement;

export interface WizardStepProps {
    isActive: boolean;
    setCanContinue: (canMoveNext: boolean) => void;
    onClose: () => void;
}

const doNothing = () => { /* Do nothing */ };

export interface WizardProps {
    open: boolean
    steps: ((props: WizardStepProps) => React.ReactElement)[];
    onClose: () => void;
}

export type CompletionHandler = () => void | Promise<unknown>;

export type CollectedWizardData = Pick<components["schemas"]["CollectedOnboardingData"], "emails" | "staff">;

type WizardContextType = {
    data: CollectedWizardData
}

const WizardContext = React.createContext<WizardContextType>({
    data: {}
});

export const useWizardCompletionData = (data: CollectedWizardData): void => {
    const ctx = useContext(WizardContext);
    ctx.data = { ...ctx.data, ...data };
};

export function Wizard(props: WizardProps): React.ReactElement {
    const { steps, open, onClose } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [canContinue, setCanContinue] = useState(true);
    const [waitingForComplete, setWaitingForComplete] = useState(false);
    const [errorMessageOnComplete, setErrorMessageOnComplete] = useState<Parameters<typeof FormattedMessage>["0"]>();

    const wizardContext = useRef<WizardContextType>({ data: {} });

    const handleBack = () => setActiveStep(activeStep - 1);

    const handleNext = () => setActiveStep(activeStep + 1);

    const handleComplete = async () => {
        setWaitingForComplete(true);
        setErrorMessageOnComplete(null);

        await staffPersonsPost(wizardContext.current.data)
            .then(() => location.reload())
            .catch((e : staffPersonsPostError) => {
                const error = e.jsonError
                    ? { values: e.jsonError.values as Record<string, object>, id: `AddPersons.ApiError.${e.jsonError.type}`}
                    : AddPersonsMessages.SubmitError;
                setErrorMessageOnComplete(error);
            })
            .finally(() => setWaitingForComplete(false));
    };

    const isFirstStep = activeStep === 0;
    const isLastStep = activeStep === steps.length - 1;

    const renderCancelButton: StepperComponent = () =>
        <SecondaryButton
            onClick={onClose}
            startIcon={<CloseOutlined />}
        >
            <FormattedMessage {...Common.close} />
        </SecondaryButton>;

    const renderPreviousButton: StepperComponent = () =>
        <SecondaryButton
            onClick={handleBack}
            startIcon={<ArrowBackOutlinedIcon />}
        >
            <FormattedMessage {...Common.back} />
        </SecondaryButton>;

    const renderNextButton: StepperComponent = () =>
        <PrimaryButton
            disabled={!canContinue}
            onClick={handleNext}
            endIcon={<ArrowForwardOutlinedIcon />}
            color="primary"
            sx={{ margin: 0, borderRadius: buttonBorderRadius }}
        >
            <FormattedMessage {...Common.next} />
        </PrimaryButton>;

    const renderCompleteButton: StepperComponent = () =>
        <Stack alignItems="center" sx={{ maxWidth: 200 }} spacing={2}>
            <PrimaryButton
                disabled={!canContinue || waitingForComplete}
                onClick={handleComplete}
                sx={{ borderRadius: buttonBorderRadius }}
                endIcon={waitingForComplete && <CircularProgress size={24} />}
            >
                <FormattedMessage {...(errorMessageOnComplete ? Common.retry : Common.finish)} />
            </PrimaryButton>
            {errorMessageOnComplete &&
                <Alert severity="error">
                    <FormattedMessage {...errorMessageOnComplete}/>
                </Alert>}
        </Stack>;

    return (
        <WizardContext.Provider value={wizardContext.current}>
            <WizardDialog open={open} id="onboardingDialog" maxWidth="xl">
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        maxHeight: "100%",
                        height: "auto"
                    }}
                >
                    {steps.map((Component, i) => {
                        const isActive = (i === activeStep);
                        return <Component
                            key={i}
                            isActive={isActive}
                            onClose={onClose}
                            setCanContinue={isActive ? setCanContinue : doNothing}
                        />;
                    })}
                    <MobileStepper
                        position="static"
                        variant="dots"
                        steps={steps.length}
                        activeStep={activeStep}
                        backButton={isFirstStep ? renderCancelButton() : renderPreviousButton()}
                        nextButton={isLastStep ? renderCompleteButton() : renderNextButton()}
                        sx={{ background: "transparent" }}
                        style={{ padding: "0 3em 2em 3em" }}
                    />
                </DialogContent>
            </WizardDialog>
        </WizardContext.Provider>
    );
}