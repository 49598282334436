import { styled } from "@mui/material";
import { useIntl } from "react-intl";
import { MINUTE_SECONDS, formatTimeDurationDay } from "../../../../../shared/common/format";
import React, { ReactElement } from "react";
import { DayTaskTemplateAccordion as Messages } from "../../../messages";
import { AddShiftData } from "../../dialogs/AddShiftPopover/AddShiftPopover";

export type DayTaskTemplateSummaryProps = {
    taskTemplates: AddShiftData["dayTaskTemplates"][0]["taskTemplates"],
};

const DenseParagraph = styled("p")({ marginBlock: 0 });

export function DayTaskTemplateSummary(props: DayTaskTemplateSummaryProps): ReactElement {
    const { taskTemplates } = props;

    const moreThanOneCostDivision = taskTemplates
        .some(tt => tt.costDivision.ref !== taskTemplates[0].costDivision.ref);

    return <>
        {taskTemplates
            .sort((a, b) => a.fromSec - b.fromSec)
            .map(tt => <DenseParagraph key={tt.ref}>
                <TaskTemplateSummary taskTemplate={tt} showCostDivision={moreThanOneCostDivision}/>
            </DenseParagraph>)}
    </>;
}

type TaskTemplateSummaryProps = {
    taskTemplate: AddShiftData["dayTaskTemplates"][0]["taskTemplates"][0],
    showCostDivision: boolean,
};

const SuggestedLineBreakSection = styled("span")({ display: "inline-block" });

function TaskTemplateSummary(props: TaskTemplateSummaryProps): ReactElement {
    const { taskTemplate, showCostDivision } = props;

    const intl = useIntl();
    const hasCustomBreak = !!taskTemplate.break;
    const hasBreakInterval = typeof taskTemplate.break?.fromSec === "number";
    const hasNoBreak = taskTemplate.break?.lengthSec === 0;

    let taskInterval = `${formatTimeDurationDay(taskTemplate.fromSec)}-${formatTimeDurationDay(taskTemplate.untilSec)}`;
    if(showCostDivision) taskInterval += ` (${taskTemplate.costDivision.abbreviation})`;
    if(hasCustomBreak) taskInterval += ",";

    let taskBreak;
    if(hasCustomBreak){
        if(hasNoBreak){
            taskBreak = intl.formatMessage(Messages.NoBreak);
        }else if(hasBreakInterval){
            taskBreak = `${intl.formatMessage(Messages.Break)} ${formatTimeDurationDay(taskTemplate.break.fromSec)}-${formatTimeDurationDay(taskTemplate.break.fromSec + taskTemplate.break.lengthSec)}`;
        }else{
            taskBreak = intl.formatMessage(Messages.BreakInMinutes, { minutes: Math.round(taskTemplate.break.lengthSec / MINUTE_SECONDS) });
        }
    }

    return <>
        <SuggestedLineBreakSection>{taskInterval}</SuggestedLineBreakSection> {hasCustomBreak && <SuggestedLineBreakSection>{taskBreak}</SuggestedLineBreakSection>}
    </>;
}