import React, { ReactElement } from "react";
import { TableCell, Stack, Box, TableCellProps } from "@mui/material";
import { StatusIcon } from "tp/site-admin/common/components/StatusIcon";
import { ApiKey } from "./ApiKeysTable";
import { FormattedMessage } from "react-intl";
import { ApiKeyStatus } from "../../messages";
import { Expired, Revoked } from "tp/shared/common-design/icons";

export interface StatusCellProps extends Omit<TableCellProps, "children"> {
    status: ApiKey["status"];
}

export function StatusCell(props: StatusCellProps): ReactElement {
    const { status, ...restProps } = props;

    let icon;
    switch (status) {
        case "Active": icon = <StatusIcon status="success" marginRight={1} />; break;
        case "Expired": icon = <Box component="div" height="24px" marginRight={1}><Expired /></Box>; break;
        case "Revoked": icon = <Box component="div" height="24px" marginRight={1}><Revoked /></Box>; break;
    }

    return (
        <TableCell {...restProps}>
            <Stack direction="row" alignItems="center">
                {icon}
                <FormattedMessage {...ApiKeyStatus[props.status]} />
            </Stack>
        </TableCell>);
}
