import React, { ReactElement, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, RadioGroup, FormControlLabel, Radio, DialogActions, Button, DialogProps } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ApiKeysTable as Messages } from "../../messages";
import { Common as SharedCommon } from "tp/shared/common/messages";
import { Common } from "tp/views/common/messages";

export interface CreateKeyDialogProps extends Pick<DialogProps, "open"> {
    onCreateKey: (expiryMonths: number) => Promise<void>
    onClose: () => void
}

export function CreateKeyDialog(props: CreateKeyDialogProps): ReactElement {
    const { open, onClose, onCreateKey } = props;
    const [selectedExpiration, setSelectedExpiration] = useState(6);

    // Reset selected expiration on close
    useEffect(() => {
        if (!open) {
            // Slight delay to ensure dialog is fully closed
            setTimeout(() => setSelectedExpiration(6), 100);
        }
    }, [open]);

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} >
            <DialogTitle>
                <FormattedMessage {...Messages.ExpirationTime} />
            </DialogTitle>
            <DialogContent>
                <RadioGroup value={selectedExpiration} onChange={e => setSelectedExpiration(parseInt(e.target.value))}>
                    {[3, 6, 12, 18, 24].map(months => (
                        <FormControlLabel
                            value={months}
                            key={months}
                            control={<Radio />}
                            label={<FormattedMessage {...Messages.ExpirationMonths} values={{ months }} />}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage {...SharedCommon.Cancel} />
                </Button>
                <Button onClick={() => onCreateKey(selectedExpiration)}><FormattedMessage {...Common.ok} /></Button>
            </DialogActions>
        </Dialog>
    );
}