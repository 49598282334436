import { GridRenderEditCellParams } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { ReactElement } from "react";
import { SharedEditProps } from "./CustomEditCell";
import DurationPicker from "./DurationPicker";
import { durationToSerializationString, serializationStringToDuration, Duration } from "../util/helperFunctions";

export default function EditTime(props: GridRenderEditCellParams & SharedEditProps): ReactElement {
    const { value, onChange } = props;

    useEffect(() => {
        if (value === undefined) {
            onChange("00:00");
        }
    }, [value]);

    const handleChange = (newValue: Duration) => {
        onChange(durationToSerializationString(newValue));
    };

    return <DurationPicker
        value={serializationStringToDuration(value ?? "00:00")}
        onChange={handleChange}
    />;
}