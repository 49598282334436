export interface IRequestHandler {
    method: string;
    url: string;
    headers?: { [index: string]: string };
    formattedPayload: string | FormData;
    payload: object | number;
    allowCache: boolean;
}

const simpleRequest = (method: string, url: string, allowCache = true): IRequestHandler => ({
    allowCache,
    formattedPayload: undefined,
    headers: {},
    method,
    payload: undefined,
    url
});

const jsonRequest = (method: string, url: string, payload: object | number): IRequestHandler => ({
    allowCache: false,
    formattedPayload: JSON.stringify(payload),
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    },
    method,
    payload,
    url
});

const formDataRequest = (method: string, url: string, payload: FormData): IRequestHandler => ({
    allowCache: false,
    formattedPayload: payload,
    method,
    payload,
    url
});

export class HttpRequest {
    static delete = (url: string): IRequestHandler => simpleRequest("DELETE", url);

    static get = (url: string, allowCache = true): IRequestHandler => simpleRequest("GET", url, allowCache);

    static post = (url: string): IRequestHandler => simpleRequest("POST", url, false);

    static patch = (url: string, patch: object): IRequestHandler => jsonRequest("PATCH", url, patch);

    static postJson = (url: string, payload: object | number): IRequestHandler => jsonRequest("POST", url, payload);

    static postFormData = (url: string, formData: FormData): IRequestHandler => formDataRequest("POST", url, formData);

    static putJson = (url: string, payload: object): IRequestHandler => jsonRequest("PUT", url, payload);
}

const toJsonRegular = (response) => response.json();

// ts version TypedApiException<>
export interface TypedBackendError {
    type: string;
    //values
}

export type JsonParser = (response: Response) => any;

export class HttpClient {
    apiHostUrl: string;

    jsonParser: JsonParser;

    constructor(apiHostUrl: string, jsonParser: JsonParser = toJsonRegular) {
        this.apiHostUrl = apiHostUrl;

        this.jsonParser = jsonParser;
    }

    responseToJson = (response: Response): object => {
        const contentType = response.headers.get("content-type");

        if (response.status >= 200 && response.status < 300) {
            if (!contentType || contentType.indexOf("text/plain") >= 0) {
                return response.text();
            } else if (contentType.indexOf("application/json") === -1) {
                return response.text().then((message) => Promise.reject({ message, response }));
            } else {
                return this.jsonParser(response);
            }
        } else {
            if (!contentType || (contentType.indexOf("text/plain") >= 0) || (contentType.indexOf("text/html") >= 0)) {
                return response.text().then((message) => Promise.reject({ message, response }));
            } else {
                return response.json().then((jsonError) => Promise.reject({ jsonError, response }));
            }
        }
    };
    
    fetch(request: IRequestHandler, signal?: AbortSignal): Promise<any> {
        const credentials: RequestCredentials = "include";

        const args = {
            credentials,
            method: request.method,
            headers: request.headers,
            body: request.formattedPayload,
            signal: signal || null
        };

        return fetch(this.apiHostUrl + request.url, args)
            .then(this.responseToJson);
    }
}
