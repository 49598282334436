import { Dialog, DialogProps, styled } from "@mui/material";
import React from "react";

const StyledDialog = styled(Dialog)(() => ({
    "& .MuiDialogContent-root": {
        padding: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },

}));

export default function WizardDialog(props: DialogProps): JSX.Element {
    return <StyledDialog PaperProps={{ elevation: 2 }} {...props} />;
}