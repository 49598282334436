import React, { Dispatch, ReactElement, useCallback } from "react";
import {
    GridRenderEditCellParams,
    GridRowId,
    useGridApiContext,
    useGridApiEventHandler,
} from "@mui/x-data-grid";
import EditText from "./EditText";
import EditDate from "./EditDate";
import EditSelection from "./EditSelection";
import EditTime from "./EditTime";
import { getNextRowId } from "../util/helperFunctions";
import { RowType } from "../models/RowType";

interface CustomEditCellProps {
    setRows: Dispatch<React.SetStateAction<RowType[]>>;
    rows: RowType[];
    fields: string[];
    onChange: (id: GridRowId, field: string, value: string) => string;
}

export interface SharedEditProps {
    onChange: (value: string) => void;
}

export default function CustomEditCell(props: GridRenderEditCellParams & CustomEditCellProps): ReactElement {
    const { id, rows, setRows, colDef, field, fields, onChange } = props;
    const apiRef = useGridApiContext();

    useGridApiEventHandler(apiRef, "cellKeyDown", useCallback((params, event) => {
        if (params.id !== id || params.field !== field) return;
        if (event.key !== "Tab") return;

        const fieldIndex = fields.indexOf(params.field);

        const tabbedToPreviousRow = fieldIndex === 0 && event.shiftKey;
        if (tabbedToPreviousRow) {
            const nextId = (params.id as number) - 1;
            const nextField = fields[fields.length - 1];

            const previousRowExists = nextId >= 0;
            if (previousRowExists) {
                moveToCell(nextId, nextField);
            }
        }

        const tabbedToNextRow = fieldIndex === (fields.length - 1) && !event.shiftKey;
        if (tabbedToNextRow) {
            const nextId = (params.id as number) + 1;
            const nextField = fields[0];

            const nextRowExists = nextId < rows.length;
            if (nextRowExists) {
                moveToCell(nextId, nextField);
            } else {
                setRows(rows.concat({
                    id: getNextRowId(rows),
                }));

                setTimeout(() => {
                    moveToCell(nextId, nextField);
                });
            }
        }

        function moveToCell(id: GridRowId, field: string): void {
            apiRef.current.setCellFocus(id, field);
            apiRef.current.setSelectionModel([id]);
        }
    }, []));

    const handleChange = (insertedValue: string): void => {
        const newValue = onChange(id, field, insertedValue);

        apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 20 });
    };

    switch (colDef.type) {
        case "date":
            return <EditDate {...props} onChange={handleChange} />;
        case "singleSelect":
            return <EditSelection {...props} onChange={handleChange} />;
        default: {
            switch (colDef.field) {
                case "hours":
                    return <EditTime {...props} onChange={handleChange} />;
                default:
                    return <EditText {...props} onChange={handleChange} />;
            }
        }
    }
}