import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../store";

interface SessionTimeoutRedirectorProps {
    redirectUrl: string;
    apiUrl?: string;
}
class SessionTimeoutRedirector extends React.Component<SessionTimeoutRedirectorProps> {

    componentDidMount(): void {
        setTimeout(this.checkSession, 10 * 1000);
    }

    checkSession = async () => {
        console.log("Checking session status");
        const nextCheckSec = await this.getNextCheckSec();
        if (nextCheckSec) {

            //https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
            const nextCheck = Math.min(nextCheckSec * 1000, 2147483647);

            setTimeout(this.checkSession, nextCheck);
        } else {
            const newUrl = location.origin + this.props.redirectUrl;
            console.log(`Session has expired or will expire in 10 s, redirect to login at: ${newUrl}`);
            window.location.replace(newUrl);
        }
    };

    getNextCheckSec = async () => {
        const response = await fetch(this.props.apiUrl + "/v1/security/whoami", { credentials: "include" });
        if (response.status === 200) {
            const json = await response.json();

            const sessionExpiresInSec = Math.abs(new Date(json.sessionExpiresUtc).getTime() - new Date().getTime()) / 1000;
            if (sessionExpiresInSec > 10) {
                const nextCheckSec = Math.max(10, sessionExpiresInSec - 5);
                console.log(`If no activity, session will expire in ${sessionExpiresInSec} s, next re-check in ${nextCheckSec} s`);
                return nextCheckSec;
            }
        } else if (response.status === 401) {
            return null;
        } else if (response.status === 404) {
            console.log("Server returned 404, developer session?");
            return 60;
        } else {
            console.log("Unknown return from session check");
            return 60;
        }
    };

    render() {
        return null;
    }
}

export default connect((state: RootState) => ({ apiUrl: state.runtime.apiUrl }))(SessionTimeoutRedirector);
