import * as React from "react";
import { CustomFormats, IntlProvider, IntlContext, MessageFormatElement } from "react-intl";

import * as moment from "moment";
import "moment/locale/sv";
import { Settings } from "luxon";

import { flattenMessages } from "../extensions";

interface LocalizationProviderProps {
    locale: string;
    localizedMessages: Record<string, unknown>;
    root?: boolean;
    children?: React.ReactElement[] | React.ReactElement;
}

export function LocalizationProvider(props: LocalizationProviderProps): React.ReactElement {
    const { localizedMessages, root, children, locale } = props;

    if (root) {
        moment.locale(locale);

        moment.updateLocale(locale, {
            longDateFormat: {
                LT: "HH:mm",
                LTS: "HH:mm:ss",
                L: "YYYY-MM-DD",
                LL: "D MMMM YYYY",
                LLL: "D MMMM YYYY [kl.] HH:mm",
                LLLL: "dddd D MMMM YYYY [kl.] HH:mm",
                lll: "D MMM YYYY HH:mm",
                llll: "ddd D MMM YYYY HH:mm"
            },
            week: {
                dow: 1, // monday is the first day of the week,
                doy: 4
            }
        });

        Settings.defaultLocale = locale;
    }

    const intl = React.useContext(IntlContext);
    const twoLetterLanguageCode = locale.substring(0, 2);

    const messages = {
        ...(intl ? intl.messages : {}),
        ...(localizedMessages[twoLetterLanguageCode] ? flattenMessages(localizedMessages[twoLetterLanguageCode]) : {})
    } as Record<string, string> | Record<string, MessageFormatElement[]>;
    
    // force 24h clock in all locales
    const defaultFormat: CustomFormats = {
        time: {
            short: {
                hour: "numeric",
                minute: "numeric",
                hour12: false
            }
        }
    };

    // force iso8601 dates 2017-07-24 in all locales
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
    const reactIntlLocale = twoLetterLanguageCode + "-ca-iso8601";

    return (
        <IntlProvider
            defaultLocale="en-ca-iso8601"
            locale={reactIntlLocale}
            messages={messages}
            defaultFormats={defaultFormat}
        >
            {children}
        </IntlProvider>
    );
}