import React, { ReactElement } from "react";
import { ConditionalTooltip } from "../../../../../views/common/components/tooltips/ConditionalTooltip";


export function DayTaskTemplateTitle(props: { name: string, abbreviation: string }): ReactElement {
    const letterLimit = 14;
    return <ConditionalTooltip
        conditional={props.name.length > letterLimit}
        tooltipProps={{ title: props.name }}
    >
        <div>{props.name.length > letterLimit ? props.abbreviation : props.name}</div>
    </ConditionalTooltip>;
}