import { injectIntl as injectOrginal, MessageFormatElement, WrappedComponentProps } from "react-intl";

export interface IReactIntlProps {
    intl?: WrappedComponentProps["intl"];
}

export function injectIntl<T>(x: T): T {
    return injectOrginal(x as any) as any;
}

// utility for react-intl
export function flattenMessages(nestedMessages: unknown, prefix = ""): Record<string, string> | Record<string, MessageFormatElement[]> {
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "string") {
            messages[prefixedKey] = value;
        } else {
            messages = { ...messages, ...flattenMessages(value, prefixedKey) };
        }

        return messages;
    }, {});
}
