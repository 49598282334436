import { styled, TextField } from "@mui/material";
import React, { ReactElement, useRef, useState } from "react";
import { parseNumberSafe, durationToDisplayString, displayStringToDuration, Duration } from "../util/helperFunctions";

const StyledTextField = styled(TextField)({
    "& label.Mui-focused": {
        border: "none",
    },
    "& .MuiInput-underline:after": {
        border: "none",
    },
    "& .MuiInputBase-root": {
        font: "inherit",
        "& fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiInputBase-input": {
        paddingLeft: "10px",
    },
});

interface DurationPickerProps {
    value: Duration;
    onChange: (value: Duration) => void;
}

export default function DurationPicker(props: DurationPickerProps): ReactElement {
    const { value: initialValue, onChange } = props;
    const [isError, setIsError] = useState(false);
    const [text, setText] = useState(durationToDisplayString(initialValue));
    const [value, setValue] = useState(initialValue);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const noCtrlCommand = !event.ctrlKey;
        const simpleKey = event.key.length === 1;
        const notNumber = event.key < "0" || "9" < event.key;

        if (noCtrlCommand && simpleKey && notNumber) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const normalizedText = normalize(event.target.value);

        setText(normalizedText);
        if (event.target.selectionStart === 2 &&
            event.target.value.length > normalizedText.length) {
            moveCursor(event.target.selectionStart + 1);
        } else {
            moveCursor(event.target.selectionStart ?? 0);
        }

        const newValue = displayStringToDuration(normalizedText);

        setIsError(newValue.minutes > 59);

        if ((value.hours !== newValue.hours || value.minutes !== newValue.minutes) &&
            newValue.minutes <= 59) {
            onChange(newValue);
            setValue(newValue);
        }
    };

    const inputRef = useRef<HTMLInputElement>();
    function moveCursor(pos: number) {
        setTimeout(() => {
            inputRef.current?.setSelectionRange(pos, pos);
        });
    }

    function normalize(text: string): string {
        const numbers = text.replace(/[^\d]/g, "");

        const totalHours = parseNumberSafe(numbers[0]) * 10 + parseNumberSafe(numbers[1]);
        return durationToDisplayString({
            days: Math.floor(totalHours / 24),
            hours: totalHours % 24,
            minutes:
                parseNumberSafe(numbers[2]) * 10 +
                parseNumberSafe(numbers[3]),
        });
    }

    return <>
        <StyledTextField
            error={isError}
            inputRef={inputRef}
            type="text"
            value={text}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={() => moveCursor(0)}
            autoFocus
        />
    </>;
}