import { FormControl, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { GridRenderEditCellParams, ValueOptions } from "@mui/x-data-grid";
import { isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { SharedEditProps } from "./CustomEditCell";

const StyledFormControl = styled(FormControl)({
    "& label.Mui-focused": {
        border: "none",
    },
    "& .MuiInput-underline:after": {
        border: "none",
    },
    "& .MuiInputBase-root": {
        font: "inherit",
        "& fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiSelect-select": {
        paddingLeft: "10px"
    },
});

export default function EditSelection(props: GridRenderEditCellParams & SharedEditProps): ReactElement {
    const { value: initialValue, onChange, colDef } = props;
    const [value, setValue] = useState(initialValue);

    const options = colDef.valueOptions as ValueOptions[];

    useEffect(() => {
        if (isUndefined(value)) {
            const newValue = getValue(options[0]);

            onChange(newValue);
            setValue(newValue);
        }
    }, [value, colDef.valueOptions]);

    const handleChange = (event: SelectChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        onChange(newValue as string);
        setValue(newValue);
    };

    return (
        <StyledFormControl fullWidth>
            <Select
                value={value ?? getValue(options[0])}
                onChange={handleChange}
                defaultOpen
                autoFocus
                displayEmpty
            >
                {options.map((opt, index) =>
                    <MenuItem
                        sx={{
                            font: "inherit"
                        }}
                        key={index}
                        value={getValue(opt)}
                    >
                        {getLabel(opt)}
                    </MenuItem>)}
            </Select>
        </StyledFormControl>
    );
}

const getValue = (option: ValueOptions): string => getProperty(option, "value");
const getLabel = (option: ValueOptions): string => getProperty(option, "label");

function getProperty(option: ValueOptions, prop: "value" | "label"): string {
    if (typeof option === "string") {
        return option;
    }
    else if (typeof option === "number") {
        return "" + option;
    }
    else {
        return option[prop];
    }
}