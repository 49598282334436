import { GridRowId } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { durationToSerializationString, getNextRowId, normalizeDuration, parseNumberSafe } from "./helperFunctions";
import { RowType } from "../models/RowType";
import { DateTime } from "luxon";

type useExcelPasteReturnType = [
    RowType[],
    React.Dispatch<React.SetStateAction<RowType[]>>,
    (id: GridRowId, field: string, value: string) => string
]

export default function useExcelPaste(initialRows: RowType[], fields: string[], refreshStaff: (rows: RowType[]) => void): useExcelPasteReturnType {
    const [rows, setRows] = useState<RowType[]>(initialRows);

    const getRowIndexFromId = (id: number): number => {
        const result = rows.findIndex(row => row.id === id);

        if (result === -1) {
            console.error(`Field value update failed. Could not find row with the id [${id}] among rows.`);
            return 0;
        }

        return result;
    };

    const getFieldIndexFromField = (field: string): number => {
        const result = fields.indexOf(field);

        if (result === -1) {
            console.error(`Field value update failed. Could not find changed field [${field}] among StaffTable fields: [${fields}]`);
            return 0;
        }

        return result;
    };

    const updateRows = (rows: RowType[], id: GridRowId, field: string, fieldMatrix: string[][]): RowType[] => {
        const indexOfChangedRow = getRowIndexFromId(id as number);
        const indexOfUpdatedField = getFieldIndexFromField(field);

        // We loop through all rows starting from the index of the edited row until we reach the end of the inserted data
        // If we run out of the original table, we append a new line to it
        let changedRowsIndex = 0;
        for (
            let rowsIndex = indexOfChangedRow;
            changedRowsIndex < fieldMatrix.length;
            rowsIndex++
        ) {
            if (rowsIndex === rows.length) {
                rows = rows.concat({ id: getNextRowId(rows) });
            }

            // Inside the row we loop through all the fields (columns) starting with the edited column wither until we reach the end of the original table
            // or end of the inserted data 
            let changedFieldIndex = 0;
            for (
                let fieldIndex = indexOfUpdatedField;
                (fieldIndex < fields.length && changedFieldIndex < fieldMatrix[changedRowsIndex].length);
                fieldIndex++
            ) {
                const currentlyUpdatedField = fields[fieldIndex];
                const proposedNewValue = fieldMatrix[changedRowsIndex][changedFieldIndex];

                const isWhiteSpace = (proposedNewValue.trim() === "");
                if (isWhiteSpace) {
                    rows[rowsIndex][currentlyUpdatedField] = undefined;
                }
                else if (currentlyUpdatedField === "startDate") {
                    rows[rowsIndex].startDate = DateTime.fromISO(proposedNewValue);
                }
                else if (currentlyUpdatedField === "hours") {
                    rows[rowsIndex][currentlyUpdatedField] = parseUnknownWorkHourFormat(proposedNewValue);
                }
                else {
                    rows[rowsIndex][currentlyUpdatedField] = proposedNewValue;
                }

                changedFieldIndex++;
            }
            changedRowsIndex++;
        }

        return rows;
    };

    function parseUnknownWorkHourFormat(text: string): string {
        const dhhmm = text.match(/^\d*\.\d{2}:\d{2}/);
        const hhmm = text.match(/^\d*:\d{2}/);
        const hh = text.match(/^\d*$/);

        if (dhhmm) {
            const [daysAndHours, minutesText] = text.split(":");
            const [daysText, hoursText] = daysAndHours.split(".");
            const [days, hours, minutes] = [daysText, hoursText, minutesText].map(parseNumberSafe);

            return durationToSerializationString(normalizeDuration({ days, hours, minutes }));
        } else if (hhmm) {
            const [hours, minutes] = text.split(":").map(parseNumberSafe);

            return durationToSerializationString(normalizeDuration({ days: 0, hours, minutes }));
        } else if (hh) {
            const hours = parseNumberSafe(text);

            return durationToSerializationString(normalizeDuration({ days: 0, hours, minutes: 0 }));
        } else {
            return undefined;
        }
    }

    const parseExcelPaste = (text: string): string[][] => {
        const matrix = text.split(/\n/).map((row: string) => row.split(/\t/)); // The entered value split to rows and columns

        if (matrix.length > 1 &&
            matrix[matrix.length - 1].length === 1 &&
            matrix[matrix.length - 1][0] === "") {
            matrix.pop();
        }

        return matrix;
    };

    /**
     * This function takes care of that the entered values get to the 'rows' state.
     * 
     * The Excel import feature is implemented here. The entered value gets split 
     * along newlines to several lines and each line is split to cell values along tabulators.
     * Then the table data is updated with these values starting from the edited cell towards the
     * right and towards the bottom until either the end of the table or end of the updated values
     * are reached.
     */
    const onCellValueChange = useCallback(
        (id: GridRowId, field: string, value: string): string => {

            const updatedFieldMatrix = parseExcelPaste(value);

            const newRows = updateRows(rows, id, field, updatedFieldMatrix);

            setRows(newRows);
            refreshStaff(newRows);

            return newRows[getRowIndexFromId(id as number)][field];
        },
        [rows]
    );

    return [rows, setRows, onCellValueChange];
}