import React, { ReactElement } from "react";
import { DataGrid, GridColDef, GridRowParams, GridSelectionModel } from "@mui/x-data-grid";
import { StaffTableMessages as Messages } from "../../../startpage/messages";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material";
import { isValidEmailAddress } from "../../../../views/common/legal";
import { sum } from "tp/shared/common/linq";
import { useOnboardingSelector } from "../../common/models/redux";
import { Black } from "../../../../shared/common-design/colors";

export interface SendInviteTableProps {
    onSelect: (emails: string[]) => void;
}

export function SendInviteTable(props: SendInviteTableProps): ReactElement {
    const { onSelect } = props;

    const intl = useIntl();
    const theme = useTheme();

    const columns: GridColDef[] = [
        {
            field: "email",
            headerName: intl.formatMessage(Messages.Email),
            width: 400,
            editable: false,
            sortable: false,
        },
        {
            field: "firstName",
            headerName: intl.formatMessage(Messages.FirstName),
            width: 200,
            editable: false,
            sortable: false,
        },
        {
            field: "lastName",
            headerName: intl.formatMessage(Messages.LastName),
            width: 200,
            editable: false,
            sortable: false,
        }
    ];

    const rows = useOnboardingSelector(state => state?.staff)
        ?.map((staff, index) => {
            return {
                id: index,
                email: staff.email,
                firstName: staff.firstName,
                lastName: staff.lastName
            };
        });

    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(rows
        .filter(row => isValidEmailAddress(row.email))
        .map(row => row.id));

    onSelect(rows
        ?.filter(row => selectionModel.indexOf(row.id) !== -1)
        .map(row => row.email));

    return (
        <div style={{
            display: "flex",
            height: "100%",
            width: `min(70vw, ${sum(columns, col => col.width ?? 0) + 50}px)`,
            marginTop: 36
        }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid
                    sx={{
                        "&.MuiDataGrid-root": {
                            border: "none",
                            color: Black,
                            fontStyle: "normal"
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            display: "none"
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold"
                        },
                        "& .MuiCheckbox-root.Mui-checked": {
                            color: theme.palette.secondary.main
                        },
                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-row.Mui-selected": {
                            backgroundColor: "unset"
                        },
                    }}
                    style={{ width: "inherit", height: "25vw" }}

                    columns={columns}
                    rows={rows}

                    checkboxSelection
                    isRowSelectable={(params: GridRowParams) => isValidEmailAddress(params.row.email)}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}

                    onProcessRowUpdateError={(error) => console.error(error)}
                    disableColumnMenu={true}
                    hideFooter={true}
                />
            </div>
        </div>
    );
}
