import React, { ReactElement, CSSProperties } from "react";
import { iconBaseStyle } from "./icons";

export function Upload(props: { style: CSSProperties }): ReactElement {
    const { style } = props;
    const width = style.width ?? iconBaseStyle.width;
    const height = style.height ?? iconBaseStyle.height;
    const color = style.color ?? iconBaseStyle.color;

    return (
        <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 15V21.5H22V15" stroke={color} strokeWidth="2" strokeLinejoin="round"/>
            <path d="M8 5.8L11.5 1L15 5.8H12.5V17H10.5V5.8H8Z" fill={color} stroke={color} strokeWidth="0.2"/>
        </svg>
    );
}