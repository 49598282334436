import * as React from "react";

export interface IHoverableState {
    hovered?: boolean;
}

export class Hoverable<TProps, TState extends IHoverableState> extends React.PureComponent<TProps, TState> {
    hoverOn = (): void => this.setState({ hovered: true });
    hoverOff = (): void => this.setState({ hovered: false });
}
