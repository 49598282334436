import React, { ReactElement, useCallback, useRef, useState } from "react";
import { styled, TextField } from "@mui/material";
import {
    GridRenderEditCellParams,
} from "@mui/x-data-grid";
import { SharedEditProps } from "./CustomEditCell";

const StyledTextField = styled(TextField)({
    "& label.Mui-focused": {
        border: "none",
    },
    "& .MuiInput-underline:after": {
        border: "none",
    },
    "& .MuiInputBase-root": {
        font: "inherit",
        paddingLeft: "10px",
        "& fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiInputBase-input": {
        font: "inherit",
    },
});

export default function EditText(props: GridRenderEditCellParams & SharedEditProps): ReactElement {
    const { value: initialValue, onChange } = props;
    const [value, setValue] = useState(initialValue);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const insertedValue = event.target.value;

        onChange(insertedValue);
        setValue(insertedValue);
    }, []);

    const inputRef = useRef<HTMLInputElement>();
    function selectAll() {
        setTimeout(() => {
            const input = inputRef.current;
            input?.setSelectionRange(0, input.value.length);
        });
    }

    return (
        <StyledTextField
            inputRef={inputRef}
            onFocus={selectAll}
            onChange={handleChange}
            value={value}
            multiline
            autoFocus
            spellCheck={false}
            fullWidth
        />
    );
}