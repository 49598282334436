import { DateTime } from "luxon";
import { JsonParser } from "./HttpClient";

export const luxonReviver: Parameters<typeof JSON.parse>[1] = (_, value) => {
    if (typeof value === "string") {
        // avoid invalid parsing of simple numbers
        if (value.length > 12) {
            const dateTime = DateTime.fromISO(value);
            if (dateTime.isValid) {
                return dateTime;
            }
        }
    }
    return value;
};

export const toJsonLuxon: JsonParser = (response) => {
    return response.text().then((text) => {
        return JSON.parse(text, luxonReviver);
    });
};