import { styled, Dialog } from "@mui/material";
import { White } from "tp/shared/common-design/colors";

export const PasswordDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        width: "380px",
        padding: "2em",
        opacity: 0.9,
    },

    "& .MuiInputBase-root": {
        width: "100%",
        marginBottom: "1.4em",
    },

    "& .MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
    },

    "& input:-webkit-autofill-internal-selected": {
        backgroundColor: "none!important",
        "WebkitBoxShadow": `200px 200px 100px ${White} inset`,
        boxShadow: `200px 200px 100px ${White} inset`,
    },

    "& input:-webkit-autofill": {
        "WebkitBoxShadow": `0 0 0px 1000px ${White} inset`,
        zIndex: -1000,
    }
});