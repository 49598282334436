import { IconButton, IconButtonProps, Snackbar, Tooltip } from "@mui/material";
import React, { ReactElement, ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ContentCopy } from "tp/shared/common-design/icons";
import { CopyToClipboardButton as Messages } from "../../messages";

export interface CopyToClipboardButtonProps extends IconButtonProps {
    value: string
}

export function CopyToClipboardButton(props: CopyToClipboardButtonProps): ReactElement {
    const { value, ...restProps } = props;
    const [snackbarContent, setSnackbarContent] = useState<ReactNode>("");

    const handleSnackbarClose = () => setSnackbarContent("");

    const handleCopy = async () => {
        await navigator.clipboard.writeText(value);
        setSnackbarContent(<FormattedMessage {...Messages.Copied} />);
    };

    return (
        <>
            <Tooltip title={<FormattedMessage {...Messages.Copy} />}>
                <IconButton onClick={handleCopy} {...restProps}>
                    <ContentCopy />
                </IconButton>
            </Tooltip>
            <Snackbar
                open={!!snackbarContent}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                onClose={handleSnackbarClose}
                autoHideDuration={1500}
                message={snackbarContent} />
        </>);
}