import React, { ReactElement } from "react";
import "./LoadingAndDone.scss";

export function LoadingAndDone(props: { done: boolean, error: boolean }): ReactElement {
    const { done, error } = props;

    const containerClassName = `circle-loader ${done && "load-complete"} ${error && "load-error"}`;
    const symbolClassName = `checkmark ${done && "draw"} ${error && "error"}`;
    const hideCheckMark = (done || error) ? { display: "" } : { display: "none" };

    return (
        <div className={containerClassName}>
            <div style={hideCheckMark} className={symbolClassName}></div>
        </div>
    );
}
