import React from "react";
import { FormattedMessage } from "react-intl";
import { StaffTable } from "tp/site-admin/onboarding/steps/4-staff-table/StaffTable";
import { injectIntl } from "tp/views/common";
import { useOnboardingSelector } from "tp/site-admin/onboarding/common/models/redux";
import { WizardStepWrapper } from "./WizardStepWrapper";
import { useWizardCompletionData, WizardStepProps } from "./Wizard";
import { AddPersonsMessages } from "../../messages";

function _StaffTableStep(props: WizardStepProps): React.ReactElement {
    const { isActive, setCanContinue, onClose } = props;

    const staff = useOnboardingSelector(state => state?.staff);
    useWizardCompletionData({ staff });

    const fields = useOnboardingSelector(state => state?.staffTableFields);

    return (
        <WizardStepWrapper
            title={<FormattedMessage {...AddPersonsMessages.StaffTableStepTitle} />}
            isActive={isActive}
            onClose={onClose}
        >
            <StaffTable setCanContinue={setCanContinue} fields={fields} />
        </WizardStepWrapper>
    );
}

export const StaffTableStep = injectIntl(_StaffTableStep);
