import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
    GridRenderEditCellParams,
} from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../views/common/store";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { SharedEditProps } from "./CustomEditCell";
import { isValidDate } from "../util/helperFunctions";
import { styled, TextField } from "@mui/material";
import { dateToString } from "../../../../../shared/common/format";

const StyledTextField = styled(TextField)({
    "& label.Mui-focused": {
        border: "none",
    },
    "& .MuiInput-underline:after": {
        border: "none",
    },
    "& .MuiInputBase-root": {
        font: "inherit",
        "& fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiInputBase-input": {
        paddingLeft: "10px",
    },
});

export default function EditDate(props: GridRenderEditCellParams<DateTime> & SharedEditProps): ReactElement {
    const { value: initialValue, onChange } = props;
    const [value, setValue] = useState<DateTime | null | undefined>(initialValue);

    const locale = useSelector((state: RootState) => state.runtime.locale);
    const shortDateFormat = useSelector((state: RootState) => state.runtime.shortDateFormat);

    useEffect(() => {
        if (value === undefined) {
            const newValue = DateTime.now();

            onChange(dateToString(newValue, shortDateFormat));
            setValue(newValue);
        }
    }, []);

    const handleChange = (date: DateTime | null) => {
        const newValue = date;

        if (isValidDate(newValue)) {
            onChange(dateToString(newValue, shortDateFormat));
        }

        setValue(newValue ?? null);
    };

    const inputRef = useRef<HTMLInputElement>();
    function moveCursor(pos: number) {
        setTimeout(() => {
            inputRef.current?.setSelectionRange(pos, pos);
        });
    }

    const hasDate = value !== undefined && value !== null;

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
            <DatePicker
                value={hasDate ? value : DateTime.now()}
                onChange={handleChange}
                renderInput={(params) =>
                    <StyledTextField
                        inputRef={inputRef}
                        onFocus={() => moveCursor(0)}
                        autoFocus
                        {...params}
                    />}
                inputFormat={shortDateFormat}
            />
        </LocalizationProvider>
    );
}