import { AlertColor, Box, BoxProps } from "@mui/material";
import React, { ReactElement } from "react";
import { iconMapping } from "../../../views/common/theme";

interface StatusIconProps extends BoxProps {
    status: AlertColor
}

export function StatusIcon(props: StatusIconProps): ReactElement {
    const { status, ...restProps } = props;
    const icon = iconMapping[status];
    return <Box component="div" height="24px" color={`${status}.main`} {...restProps}>{icon}</Box>;
}
