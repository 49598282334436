import * as React from "react";
import * as ReactDOM from "react-dom";
import { createTheme, Theme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "../../views/common/components";
import { theme } from "../../views/common/theme";
import { FormulaPopUpProps } from "../common/components/dialogs/FormulaPopUp/FormulaPopUp";
import { PrintContractPopUpProps, PrintContractPopUp } from "../staff-contract/components/DigitalSigningLog/PrintContractPopUp";
import { DigitalSigningLog, DigitalSigningLogProps } from "../staff-contract/components/DigitalSigningLog/DigitalSigningLog";
import { configureStore, injectAsyncReducers, store } from "../../views/common/store";
import { Provider } from "react-redux";
import { AddPersons, AddPersonsProps } from "../staff/components/AddPersons/AddPersons";
import { onboardingReducer } from "../onboarding/common/models/reducer";
import { AddShiftPopover, AddShiftPopoverProps } from "../common/components/dialogs/AddShiftPopover";
import signingLogsReducer from "../staff-contract/components/DigitalSigningLog/reducers/signingLogsReducer";
import { ResetPassword, ResetPasswordProps } from "../login/components/ResetPassword/ResetPassword";
import { ApiKeysTable, ApiKeysTableProps } from "../user/components/ApiKeysTable";
import { PersonMultiSelect, PersonMultiSelectProps } from "../user/components/PersonMultiSelect";
import { initialize as initializeApi } from "tp/shared/timeplan-api-client";
import { getUrls } from "tp/shared/common/reducers/runtime";
import { DatesToString } from "tp/shared/types";
import { ReactElement } from "react";
import { TaskRotationModal, TaskRotationModalProps } from "../staff-schedules/components/TaskRotationModal";

interface ReactWebFormsProps {
    locale: string;
    elementId: string;
}

const localizedMessages = {
    sv: {
        ...require("tp/shared/common/intl/sv.json"),
        ...require("tp/views/common/intl/sv.json"),
        ...require("tp/site-admin/common/intl/sv.json"),
        ...require("./intl/sv.json")
    },
    en: {
        ...require("tp/views/common/intl/en.json"),
        ...require("./intl/en.json")
    }
};

export function renderComponent<E>(ComponentToRender: React.FunctionComponent<E>, props: E, reactWebFormsProps: ReactWebFormsProps): void {
    const { locale, elementId } = reactWebFormsProps;

    initializeApi(getUrls());

    const root = true;
    const rootComponent = (
        <Provider store={store}>
            <LocalizationProvider {...{ locale, localizedMessages, root }}>
                <ThemeProvider theme={theme}>
                    <ComponentToRender {...props} />
                </ThemeProvider>
            </LocalizationProvider>
        </Provider>
    );

    ReactDOM.render(
        rootComponent,
        document.getElementById(elementId)
    );
}

export const renderFormulaPopUp = (props: FormulaPopUpProps & { locale: string }): Promise<void> =>
    import(/* webpackChunkName: "FormulaPopUp" */"../common/components/dialogs/FormulaPopUp/FormulaPopUp").then(m => {
        const { locale, ...restProps } = props;
        renderComponent<FormulaPopUpProps>(
            m.FormulaPopUp,
            restProps,
            {
                locale,
                elementId: "reactPopupRoot"
            }
        );
    });

export const renderPrintContractPopUp = (props: PrintContractPopUpProps & { locale: string }): void => {
    const { locale, ...restProps } = props;
    renderComponent<PrintContractPopUpProps>(PrintContractPopUp, restProps, { locale, elementId: "reactPopupRoot" });
};

export const renderDigitalSigningLog = (props: DigitalSigningLogProps & { locale: string }): void => {
    const { locale, ...restProps } = props;

    configureStore(locale, null, {}, "yyyy-MM-dd", "yyyy-MM-dd HH:mm");
    injectAsyncReducers({ digitalSigningLog: signingLogsReducer });

    renderComponent<DigitalSigningLogProps>(
        DigitalSigningLog,
        restProps,
        {
            locale,
            elementId: "rightPanelContainer"
        }
    );
};

export const renderResetPassword = (props: ResetPasswordProps & { locale: string }): void => {
    const { locale, ...restProps } = props;
    renderComponent<ResetPasswordProps>(ResetPassword, restProps, { locale, elementId: "reactPopupRoot" });
};

export const renderAddPersons = (props: AddPersonsProps & { locale: string }): void => {
    const { locale, ...restProps } = props;

    try {
        configureStore(locale, null, {}, "yyyy-MM-dd", "yyyy-MM-dd HH:mm");
        injectAsyncReducers({ onboarding: onboardingReducer });

        restProps.onClose = function () {
            restProps.open = false;
            renderComponent<AddPersonsProps>(AddPersons, restProps, { locale, elementId: "rightPanelContainer" });
        };

        restProps.open = true;
        renderComponent<AddPersonsProps>(AddPersons, restProps, { locale, elementId: "rightPanelContainer" });
    }
    catch (error) {
        console.error(error);
    }
};

export const renderAddShiftPopover = (props: AddShiftPopoverProps & { locale: string }): void => {
    const { locale, ...restProps } = props;
    renderComponent<AddShiftPopoverProps>(AddShiftPopover, restProps, { locale, elementId: "reactPopupRoot" });
};

export const renderPersonMultiSelect = (props: PersonMultiSelectProps & { locale: string, elementId: string }): void => {
    const { locale, elementId, ...restProps } = props;
    renderComponent<PersonMultiSelectProps>(PersonMultiSelect, restProps, { locale, elementId });
};

const ApiKeysTableWithLegacyTheme = (props: DatesToString<ApiKeysTableProps>): ReactElement => (
    <ThemeProvider<Theme> theme={(theme) => createTheme({
        ...theme,
        typography: {
            ...theme.typography,
            body1: {
                ...theme.typography.body1,
                fontSize: "12px",
                lineHeight: "normal"
            },
            body2: {
                ...theme.typography.body2,
                fontSize: "12px",
                lineHeight: "normal"
            },
            button: {
                ...theme.typography.button,
                fontSize: "12px",
                lineHeight: "normal"
            }
        },
        components: {
            ...theme.components,
            MuiTableContainer: {
                ...theme.components?.MuiTableContainer,
                styleOverrides: {
                    ...theme.components?.MuiTableContainer?.styleOverrides,
                    root: {
                        ...(theme.components?.MuiTableContainer?.styleOverrides?.root as object),
                        height: props.keys.length <= 1
                            ? "90px"
                            : props.keys.length === 2
                                ? "120px"
                                : "150px"
                    }
                }
            }
        }
    })}>
        <ApiKeysTable {...props} />
    </ThemeProvider>
);

export const renderApiKeysTable = (props: DatesToString<ApiKeysTableProps> & { locale: string, elementId: string }): void => {
    const { locale, elementId, ...restProps } = props;
    renderComponent(ApiKeysTableWithLegacyTheme, restProps, { locale, elementId });
};

const TaskRotationModalWithLegacyTheme = (props: TaskRotationModalProps): ReactElement => (
    <ThemeProvider<Theme> theme={(theme) => createTheme({
        ...theme,
        typography: {
            ...theme.typography,
            h3: {
                ...theme.typography.h3,
                fontSize: "12px",
            }
        },
    })}>
        <TaskRotationModal {...props} />
    </ThemeProvider>
);
export const renderTaskRotationModal = (props: TaskRotationModalProps & { locale: string, elementId: string }): void => {
    const { locale, elementId, ...restProps } = props;
    renderComponent<TaskRotationModalProps>(TaskRotationModalWithLegacyTheme, { ...restProps, key: restProps.open }, { locale, elementId });
};