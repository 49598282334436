export interface IGrouping<TKey, TElement> {
    key: TKey;
    data: TElement[];
}

// c# all = js every

// c# any = js some

export function findIndex<TSource>(array: TSource[], predicate: (source: TSource) => boolean): number {
    let index = 0;
    for (const item of array) {
        if (predicate(item)) {
            return index;
        }

        index++;
    }

    return -1;
}

export function groupBy<TSource, TKey extends PropertyKey>(
    array: TSource[],
    keySelector: (source: TSource) => TKey
): Array<IGrouping<TKey, TSource>> {
    const map: Record<PropertyKey, TSource[]> = {};
    array.map((e) => ({ k: keySelector(e), d: e })).forEach((e) => {
        map[e.k] = map[e.k] || [];
        map[e.k].push(e.d);
    });
    return Object.keys(map).map((k: any) => ({ key: k, data: map[k] }));
}

const buildStringCompare = (keySelector) => (left, right) => keySelector(left).localeCompare(keySelector(right));

const buildNumberCompare = (keySelector) => (left, right) => keySelector(left) - keySelector(right);

export function max<TSource, TMax>(array: TSource[], selector: (source: TSource) => TMax): TMax {
    const valueArray = array.map(selector);
    return Math.max(...valueArray as number[]) as TMax;
}

export function min<TSource, TMin>(array: TSource[], selector: (source: TSource) => TMin): TMin {
    const valueArray = array.map(selector);
    return Math.min(...valueArray as number[]) as TMin;
}

function orderBy<TSource>(array: TSource[], compareFn: (left: TSource, right: TSource) => number) {
    return array.slice(0).sort(compareFn);
}

export function orderByString<TSource>(array: TSource[], keySelector: (source: TSource) => string): TSource[] {
    return orderBy(array, buildStringCompare(keySelector));
}

export function orderByNumber<TSource>(array: TSource[], keySelector: (source: TSource) => number): TSource[] {
    return orderBy(array, buildNumberCompare(keySelector));
}

export function range(start: number, count: number): number[] {
    return Array.from({ length: count }, (v, k) => k + start);
}

export function selectMany<TSource, TResult>(array: TSource[], selector: (source: TSource) => TResult[]): TResult[] {
    const callback = (prevVal: TResult[], current: TSource) => prevVal.concat(selector(current));
    return array.reduce(callback, []);
}

export function sum<TSource>(array: TSource[], keySelector: (source: TSource) => number): number {
    return array.reduce((prev, current) => keySelector(current) + prev, 0);
}

export function count<TSource>(array: TSource[], keySelector: (source: TSource) => boolean): number {
    return array.reduce((prev, current) => (keySelector(current) ? 1 : 0) + prev, 0);
}

export function skipWhile<TSource>(array: TSource[], predicate: (source: TSource) => boolean): TSource[] {
    let predicateMet = false;

    return array.filter((value) => {
        if (predicateMet) {
            return true;
        } else {
            predicateMet = !predicate(value);
            return predicateMet;
        }
    });
}

export function takeWhile<TSource>(array: TSource[], predicate: (source: TSource) => boolean): TSource[] {
    let predicateMet = false;

    return array.filter((value) => {
        if (predicateMet) {
            return false;
        } else {
            predicateMet = !predicate(value);
            return !predicateMet;
        }
    });
}
