import { FormControlLabel, FormControlLabelProps, RadioGroup, RadioGroupProps } from "@mui/material";

export interface TypedRadioGroupProps<T extends string> extends Omit<RadioGroupProps, "value" | "defaultValue" | "onChange"> {
    value?: T,
    defaultValue?: T,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: T) => void;
}

export const TypedRadioGroup: <T extends string>(props: TypedRadioGroupProps<T>) => JSX.Element = RadioGroup;

export interface TypedFormControlLabelProps<T extends string> extends Omit<FormControlLabelProps, "value"> {
    value?: T
}

export const TypedFormControlLabel: <T extends string>(props: TypedFormControlLabelProps<T>) => JSX.Element = FormControlLabel;