import * as React from "react";

import { FormattedMessage } from "react-intl";

type FormattedMessageContainer<T> = {
    [P in keyof T]: JSX.Element;
};

export function messagify<T>(t: T): FormattedMessageContainer<T> {
    const result = {} as FormattedMessageContainer<T>;
    for (const k in t) {
        result[k] = <FormattedMessage {...t[k] } />;
    }
    return result;
}
