import React, { useEffect, useState } from "react";
import { DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText, DialogProps, CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { validateEmail } from "../../../common/validation";
import { Common, Messages } from "tp/views/common/messages";
import * as api from "./api";
import { getUrls } from "tp/shared/common/reducers/runtime";
import { PasswordDialog } from "../../../common/components/dialogs/PasswordDialog";

export type ResetPasswordProps = Pick<DialogProps, "open"> & {
    onClose: () => void
    email: string
    userIsLocked: boolean
};

export function ResetPassword(props: ResetPasswordProps): React.ReactElement {
    const { email: initialEmail, open, onClose, userIsLocked } = props;

    const [email, setEmail] = useState(initialEmail);
    const [invalidEmail, setInvalidEmail] = useState(() => !validateEmail(initialEmail));
    const [genericError, setGenericError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => api.initialize(getUrls().apiV1Url), []);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setInvalidEmail(!validateEmail(event.target.value));
    };

    const handleCloseConfirm = () => {
        setSubmitted(false);
        onClose();
    };

    const submit = async () => {
        try {
            setLoading(true);
            setGenericError(false);
            await api.resetPassword(email);
            setSubmitted(true);
        } catch (err) {
            setGenericError(true);
        }
        finally {
            setLoading(false);
        }
    };

    const ResetDialog = () => (
        <PasswordDialog open={open} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage id="ResetPassword.Title" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id={userIsLocked ? "ResetPassword.LockedDescription" : "ResetPassword.Description"} />
                </DialogContentText>

                <TextField
                    id="email"
                    type="email"
                    autoFocus
                    fullWidth
                    error={invalidEmail}
                    label={<FormattedMessage {...Common.email} />}
                    value={email}
                    onChange={handleEmailChange}
                    variant="standard" />
            </DialogContent>
            <DialogActions>
                {genericError && (
                    <div className="red">
                        <FormattedMessage {...Messages.GenericApiError} />
                    </div>)}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submit}
                    disabled={invalidEmail || loading}
                >
                    {loading
                        ? <CircularProgress size={20} />
                        : <FormattedMessage {...Common.ok} />}
                </Button>
            </DialogActions>
        </PasswordDialog>);

    const ConfirmDialog = () => (
        <PasswordDialog open={open} onClose={handleCloseConfirm}>
            <DialogTitle>
                <FormattedMessage id="ResetPassword.Title" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="ResetPassword.Confirmation" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCloseConfirm}
                >
                    <FormattedMessage {...Common.ok} />
                </Button>
            </DialogActions>
        </PasswordDialog>
    );

    return submitted ? ConfirmDialog() : ResetDialog();
}
