import { Alert, AlertProps } from "@mui/material";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { LoadState } from "tp/views/common/models";
import { useDigSigLogSelector } from "./redux";

export function SigningStatusBox(): ReactElement {
    const { signingStatus, loadState } = useDigSigLogSelector(state => state.digitalSigningLog);

    const statusToTypeAndTitle: Record<typeof signingStatus, { type: AlertProps["severity"], titleId: string, numSigned?: string }> = {
        ["New"]: { type: "info", titleId: "DigitalSigningLog.NewContract", numSigned: undefined },
        ["Sent"]: { type: "info", titleId: "DigitalSigningLog.SentContract", numSigned: "0" },
        ["PartiallySigned"]: { type: "info", titleId: "DigitalSigningLog.SentContract", numSigned: "1" },
        ["Signed"]: { type: "success", titleId: "DigitalSigningLog.SignedByAllParties", numSigned: undefined },
        ["Rejected"]: { type: "error", titleId: "DigitalSigningLog.RejectedContract", numSigned: undefined }
    };
    const { type, titleId, numSigned } = statusToTypeAndTitle[signingStatus];

    const titleStyle = {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };

    return (
        <Alert
            severity={type}
            style={loadState === LoadState.Idle ? {} : { visibility: "hidden" }}
        >
            <div style={{ width: "100%" }}>
                <div  style={titleStyle}>
                    <FormattedMessage id={titleId} values={{ numSigned: numSigned }} />
                </div>
            </div>
        </Alert>
    );
}
