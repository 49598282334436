import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { marginSm } from "tp/shared/common-design/margins";
import { SelectInput } from "../../../../views/common-forms/components/Selects";
import { timeStringToSeconds } from "tp/site-admin/dayoverview/models/task";
import { TimeInput as TimeInputMessages, BreakInput as BreakInputMessages } from "../../messages";
import { TimeTextField } from "../../../../views/common/components/inputs/TimeTextField";
import { FormLabel } from "../layout/FormLabel";
import { DAY_SECONDS, formatTimeDurationDay } from "../../../../shared/common/format";

interface BreakInputProps {
    snapSec: number;
    taskFromSec: number;
    taskUntilSec: number;
    breakFromSec: number;
    onBreakFromSecChange: (breakFromSec: number) => void;
    onBreakLengthSecChange: (breakLengthSec: number) => void;
    breakLengthSec: number;
    breakLengthOptions: number[];
    disabled?: boolean;
}

enum BreakOptions {
    Break = "break",
    NoBreak = "no-break"
}

export function BreakInput(props: BreakInputProps): ReactElement {
    const { taskFromSec, taskUntilSec, breakFromSec, breakLengthSec, breakLengthOptions, onBreakFromSecChange, onBreakLengthSecChange, snapSec, disabled } = props;

    const [validStartSec, setValidStartSec] = useState(true);
    const [validLengthSec, setValidLengthSec] = useState(true);

    const [selectedOption, setSelectedOption] = useState<BreakOptions>(breakLengthSec === 0 ? BreakOptions.NoBreak : BreakOptions.Break);
    const [fromText, setFromText] = useState(isNaN(breakFromSec) ? "" : formatTimeDurationDay(breakFromSec));
    const [selectedBreakLength, setSelectedBreakLength] = useState(isNaN(breakLengthSec) || breakLengthSec === 0 ? "" : breakLengthSec);

    useEffect(() => {
        if (selectedOption === BreakOptions.NoBreak) {
            setValidStartSec(true);
            setValidLengthSec(true);

            onBreakFromSecChange(NaN);
            onBreakLengthSecChange(0);
        }
        else {
            let startSec = timeStringToSeconds(fromText);
            if (startSec < taskFromSec) {
                startSec += DAY_SECONDS;
            }
            if (snapSec > 0) {
                startSec = Math.round(startSec / snapSec) * snapSec;
            }

            const minBreakLength = Math.min(...breakLengthOptions);

            const validStartSec = !isNaN(startSec)
                && startSec >= taskFromSec && startSec + minBreakLength <= taskUntilSec;
            setValidStartSec(validStartSec);

            const validLengthSec = validStartSec && typeof selectedBreakLength === "number"
                && startSec + selectedBreakLength <= taskUntilSec;
            setValidLengthSec(validLengthSec);

            onBreakFromSecChange(validStartSec ? startSec : NaN);
            onBreakLengthSecChange(validLengthSec ? selectedBreakLength as number : NaN);
        }
    }, [selectedOption, fromText, selectedBreakLength, taskFromSec, taskUntilSec, snapSec, breakLengthOptions]);

    const handleStartBlur = () => {
        if (!isNaN(breakFromSec)) {
            setFromText(formatTimeDurationDay(breakFromSec));
        }
    };

    const handleStartFocus = () => {
        setSelectedOption(BreakOptions.Break);
    };

    const input = (
        <>
            <TimeTextField
                label={<FormattedMessage {...TimeInputMessages.Starttime} />}
                value={fromText}
                style={{ marginRight: marginSm }}
                onChange={e => setFromText(e.target.value)}
                onBlur={handleStartBlur}
                onFocus={handleStartFocus}
                error={!!(fromText && !validStartSec && !disabled)}
                helperText={breakFromSec > DAY_SECONDS && <FormattedMessage {...TimeInputMessages.NextDay} /> || " "}
                disabled={disabled}
            />
            <SelectInput
                label={<FormattedMessage {...TimeInputMessages.Endtime} />}
                value={selectedBreakLength}
                sx={{ width: 85 }}
                onChange={e => setSelectedBreakLength(e.target.value as number)}
                error={!!(fromText && !validLengthSec && !disabled)}
                helperText={breakFromSec + breakLengthSec > DAY_SECONDS && <FormattedMessage {...TimeInputMessages.NextDay} /> || " "}
                disabled={disabled || isNaN(breakFromSec)}>
                {breakLengthOptions.map(sec => {
                    const fromTextTime = timeStringToSeconds(fromText);
                    return (
                        <MenuItem key={sec} value={sec}>
                            {isNaN(fromTextTime) ? "" : formatTimeDurationDay(fromTextTime + sec)}
                        </MenuItem>);
                })}
            </SelectInput>
        </>);

    return (
        <FormControl disabled={disabled}>
            <FormLabel disabled={disabled}><FormattedMessage {...BreakInputMessages.Label} /></FormLabel>
            <RadioGroup
                value={disabled ? null : selectedOption}
                onChange={(e) => setSelectedOption(e.target.value as BreakOptions)}
                sx={{ mt: 1.5 }}
            >
                <FormControlLabel
                    value={BreakOptions.Break}
                    control={<Radio sx={{ top: -9 /* Offset for helper text */ }} size="small" />}
                    label={input}
                />
                <FormControlLabel
                    sx={{ mt: "-10px" }}
                    value={BreakOptions.NoBreak}
                    control={<Radio size="small" />}
                    label={<FormattedMessage {...BreakInputMessages.NoBreak} />}
                />
            </RadioGroup>
        </FormControl>
    );
}