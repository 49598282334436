import { TextField } from "@mui/material";
import React from "react";

export function TimeTextField({ style, FormHelperTextProps, ...restProps }: React.ComponentProps<typeof TextField>): React.ReactElement {
    return (
        <TextField
            variant="outlined"
            size="small"
            style={{ width: "70px", ...(style || {}) }}
            inputProps={{ style: { fontSize: "12px" } }}
            FormHelperTextProps={FormHelperTextProps || { sx: { margin: "auto", fontSize: "0.65rem", height: "10px" } }}
            placeholder="00:00"
            {...restProps} />
    );
}
