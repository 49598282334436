import { DateTime } from "luxon";
import moment from "moment";
import { CostDivisionEntry, DeviationEntry, EmploymentEntry, TimeDataEntry } from "../models";
import { HttpClient, HttpRequest } from "tp/views/common/HttpClient";
import { TimeplanURLs } from "tp/shared/common/reducers/runtime";
import { initialize as apiClientInitialize } from "tp/shared/timeplan-api-client";
import { toJsonMoments } from "tp/views/common/momentParsing";
import { toJsonLuxon } from "tp/views/common/luxonParsing";

let momentClient: HttpClient = null;
let luxonClient: HttpClient = null;

interface RequestPaymentInfoResponse {
    redirectUrl: string;
}

interface DeviationResponse {
    costDivisionList: CostDivisionEntry[];
    deviations: DeviationEntry[];
}

export interface TimeDataResponse {
    costDivisions: string[];
    accountGroups: string[];
    entries: TimeDataEntry[];
}

export const initialize = (urls: TimeplanURLs): void => {
    momentClient = new HttpClient(urls.apiV1Url, toJsonMoments);
    luxonClient = new HttpClient(urls.apiV1Url, toJsonLuxon);
    apiClientInitialize(urls);
};

function clientFetch<T>(
    method: string,
    endpoint: string,
    queryOptions?: Record<string, string>,
    signal?: AbortSignal
): Promise<T> {
    let request;

    let queryString = endpoint;
    if (queryOptions) {
        queryString += `?${new URLSearchParams(queryOptions).toString()}`;
    }

    if (method === "GET") {
        request = HttpRequest.get(queryString);
    }

    return momentClient.fetch(request, signal) as Promise<T>;
}

export function requestPaymentInfo(): Promise<RequestPaymentInfoResponse> {
    return momentClient.fetch(HttpRequest.get("/store/paymentInfo")) as Promise<RequestPaymentInfoResponse>;
}

export function setEmailAsUserName(email: string): Promise<Response> {
    const request = { email };

    return momentClient.fetch(HttpRequest.postJson("/security/setemailusername", request)) as Promise<Response>;
}
export const getDeviations = (
    fromDate: DateTime,

    untilDate: DateTime,

    deviationCodes: number[],

    signal: AbortSignal
): Promise<DeviationResponse> => {
    let extraArgs = "";

    deviationCodes.forEach((deviationCodeRef) => {
        extraArgs += `&deviationsChecked=${deviationCodeRef}`;
    });

    return luxonClient.fetch(
        HttpRequest.get(
            `/dashboard/deviations?fromDate=${fromDate.toISODate()}&untilDate=${untilDate.toISODate()}${extraArgs}`
        ),
        signal
    ) as Promise<DeviationResponse>;
};

export function getTimeData(
    fromDate: moment.Moment,
    untilDate: moment.Moment,
    signal: AbortSignal
): Promise<TimeDataResponse> {
    return clientFetch<TimeDataResponse>(
        "GET",
        "/dashboard/timedata",
        {
            fromDate: fromDate.format("YYYY-MM-DD"),
            untilDate: untilDate.format("YYYY-MM-DD"),
        },
        signal
    );
}

export function getEmployments(
    fromDate: moment.Moment,
    untilDate: moment.Moment,
    signal: AbortSignal
): Promise<EmploymentEntry[]> {
    return clientFetch<EmploymentEntry[]>(
        "GET",
        "/dashboard/employments",
        {
            fromDate: fromDate.format("YYYY-MM-DD"),
            untilDate: untilDate.format("YYYY-MM-DD"),
        },
        signal
    );
}
