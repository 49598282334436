import React, { ReactElement } from "react";
import { FormLabelProps, FormLabel as MuiFormLabel, Typography } from "@mui/material";

export const labelHeight = 26;

export const FormLabel = (props: FormLabelProps): ReactElement =>
{
    const {children, sx, disabled, ...rest} = props;

    return (
        <MuiFormLabel sx={{ height: labelHeight, ...sx }} {...rest}>
            <Typography variant="h2" whiteSpace="nowrap" color={!disabled ? "primary" : null}>
                {children}
            </Typography>
        </MuiFormLabel> );
};