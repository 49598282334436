import { Black, Gray1, Gray2 } from "../../shared/common-design/colors";

export const borderRadius = 5;

export const buttonBorderRadius = "20px";

export const topBorderRadius = (x: number = borderRadius): string => `${x}px ${x}px 0px 0px`;

export const grayBorder = `1px solid ${Gray2}`;
export const containerBorder = `2px solid ${Gray1}`;

export const boxShadow = `0 5px 8px 0 ${Black}`;