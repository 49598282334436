import { ThunkAction } from "redux-thunk";
import { RowType } from "../../steps/4-staff-table/models/RowType";
import { OnboardingAction } from "./reducer";
import { OnboardingCombinedState } from "./redux";
import { OnboardingState } from "./onboardingState";
import { onboardingCollectiveagreementsGet } from "../../../../shared/timeplan-api-client";

export enum ActionType {
    REFRESH_COLLECTIVE_AGREEMENT_OPTIONS = "REFRESH_COLLECTIVE_AGREEMENT_OPTIONS",
    SET_COLLECTIVE_AGREEMENT_OPTIONS = "SET_COLLECTIVE_AGREEMENT_OPTIONS",
    SET_STAFF_TABLE_FIELDS = "SET_STAFF_TABLE_FIELDS",
    SET_STAFF_LIST = "SET_STAFF_LIST",
}

export function refreshCollectiveAgreementOptions(priceId: string): ThunkAction<Promise<void>, OnboardingCombinedState, unknown> {
    return async (dispatch) => {
        const data = await onboardingCollectiveagreementsGet(priceId);

        data && dispatch({
            type: ActionType.REFRESH_COLLECTIVE_AGREEMENT_OPTIONS,
            collectiveAgreementOptions: data
        });
    };
}

export function setCollectiveAgreementOptions(collectiveAgreementOptions: OnboardingState["collectiveAgreementOptions"]): OnboardingAction {
    return {
        type: ActionType.SET_COLLECTIVE_AGREEMENT_OPTIONS,
        collectiveAgreementOptions: collectiveAgreementOptions
    };
}

export function setStaffTableFields(staffTableFields: string[]): OnboardingAction {
    return {
        type: ActionType.SET_STAFF_TABLE_FIELDS,
        staffTableFields: staffTableFields
    };
}

export function setStaffList(staff: OnboardingState["staff"], unfilteredStaff: RowType[]): OnboardingAction {
    return {
        type: ActionType.SET_STAFF_LIST,
        stafflist: { staff, unfilteredStaff }
    };
}