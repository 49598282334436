import React, { ReactElement } from "react";
import { injectIntl, IReactIntlProps } from "../extensions";
import { Common } from "../messages";

declare global {
    interface Window { ConfirmNavigate: () => number | boolean; }
}

interface ConfirmNavigateOnEditProps extends IReactIntlProps {
    warn: boolean;
}

@injectIntl
export class ConfirmNavigateOnEdit extends React.Component<ConfirmNavigateOnEditProps> {

    componentDidMount(): void {
        const w = window;
        w.ConfirmNavigate = this.confirmNavigate;
    }

    confirmNavigate = (): boolean => {
        const { intl, warn } = this.props;

        return warn ? confirm(intl.formatMessage(Common.confirmNavigateOnEdit)) : true;
    };

    render(): ReactElement {
        return null;
    }
}
