import React, { ReactElement } from "react";
import { Tooltip, TooltipProps } from "@mui/material";

interface ConditionalTooltipProps {
    conditional: boolean;
    tooltipProps: Omit<TooltipProps, "children">;
    children: TooltipProps["children"];
    useDiv?: boolean; // To encapsulate possibly disabled children
}

export function ConditionalTooltip(props: ConditionalTooltipProps): ReactElement {
    const { conditional, useDiv, children } = props;
    const tooltipProps = {
        enterDelay: 500,
        title: "",
        ...props.tooltipProps
    };

    return (<>
        {conditional ?
            <Tooltip {...tooltipProps} disableInteractive>
                {useDiv ?
                    <div>{children}</div>
                    :
                    children
                }
            </Tooltip>
            :
            children
        }
    </>);
}
