import React, { useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Common } from "../../../../views/common/messages";
import { Common as SharedCommon } from "tp/shared/common/messages";

export interface ConfirmDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => (void | Promise<void>);
    title: React.ReactNode;
    children?: React.ReactElement[] | React.ReactElement;
}


export function ConfirmDialog(props: ConfirmDialogProps): React.ReactElement {
    const { open, onClose, onConfirm, title, children } = props;

    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        setLoading(true);
        await onConfirm();
        setLoading(false);
        onClose();
    };

    const actions = [
        <Button
            key="confirm"
            color="primary"
            variant="contained"
            onClick={handleConfirm}>
            {loading ? <CircularProgress size={24} sx={{ color: "primary.contrastText" }} /> : <FormattedMessage {...Common.ok} />}
        </Button>,
        <Button
            key="cancel"
            color="primary"
            onClick={onClose}>
            <FormattedMessage {...SharedCommon.Cancel} />
        </Button>
    ];

    return (
        <Dialog
            open={open || false}
            maxWidth="sm"
        >
            <DialogTitle>{title}</DialogTitle>
            {children && <DialogContent>{children}</DialogContent>}
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
}