import * as React from "react";
import { ArrowDropDown } from "tp/shared/common-design/icons";
import Select, { components, StylesConfig, ThemeConfig } from "react-select";
import { Black, TPTurquoise, White } from "../../shared/common-design/colors";
import { FormattedMessage } from "react-intl";
import { Common } from "../common/messages";

export const baseStyle: StylesConfig = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused || state.isSelected ? White : Black
    }),
    valueContainer: provided => ({
        ...provided,
        padding: 0
    })
};

const customTheme: ThemeConfig = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        neutral90: White,
        primary: TPTurquoise,
        primary25: TPTurquoise
    },
});

export const DropdownIndicator = (
    props: React.ComponentProps<typeof components.DropdownIndicator>
): JSX.Element => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowDropDown />
        </components.DropdownIndicator>
    );
};

const isOptionSelected = (): boolean => false;

export const selectTemplate: Partial<React.ComponentProps<typeof Select>> = {
    components: { DropdownIndicator, IndicatorSeparator: null },
    isOptionSelected,
    menuPlacement: "auto",
    menuPosition: "fixed",
    theme: customTheme,
    placeholder: <FormattedMessage {...Common.select} />
};

export interface GenericSelectOption<TValue> {
    label: string | JSX.Element;
    value: TValue;
    isDisabled?: boolean;
}

export type SelectOption = GenericSelectOption<number>;
export type SelectStateOption = SelectOption & { state: boolean };

export function createSelectOption<TValue>(value: TValue, label: string | JSX.Element, isDisabled = false): GenericSelectOption<TValue> {
    return {
        value,
        label,
        isDisabled
    };
}
