import * as classnames from "classnames";
import * as React from "react";
import { LoadState } from "../models";
import { ReactElement } from "react";

export const MessageOverlay = ({ state, children }: { state: LoadState; children?: ReactElement }): ReactElement => {
    if (state === LoadState.Idle) {
        return null;
    } else {
        const className = classnames("messageOverlay", { slow: state === LoadState.Slow });
        
        return (
            <div className={className}>
                {children}
            </div>
        );
    }
};
