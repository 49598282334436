import React, { MouseEventHandler, ReactElement, ReactNode, useRef, useState } from "react";
import { ClickAwayListener } from "@mui/base";
import { ButtonGroup, Button, Popper, Grow, Paper, MenuList, MenuItem, ButtonGroupProps, ButtonProps } from "@mui/material";
import { ArrowDropDown } from "tp/shared/common-design/icons";

type SplitButtonOption = {
    onClick?: MouseEventHandler,
    value: ReactNode
};

export type SplitButtonProps = Pick<ButtonGroupProps, "disabled"> & Pick<ButtonProps, "startIcon"> & {
    options: SplitButtonOption[]
}

// Heavily inspired by https://mui.com/material-ui/react-button-group/#split-button
export function SplitButton(props: SplitButtonProps): ReactElement {
    const { options, disabled, startIcon } = props;

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const anchorRef = useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        options[index].onClick && options[index].onClick(event);
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} disabled={disabled}>
                <Button startIcon={startIcon} onClick={options[selectedIndex].onClick}>{options[selectedIndex].value}</Button>
                <Button size="small" onClick={handleToggle}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[ // Modifier taken from https://mui.com/material-ui/react-popper/#scroll-playground
                    {
                        name: "preventOverflow",
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: "document",
                            padding: 8,
                        },
                    }
                ]}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={index}
                                            selected={index === selectedIndex}
                                            onClick={(e) => handleMenuItemClick(e, index)}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}