import { GridCellParams, GridSelectionModel } from "@mui/x-data-grid";
import { isValidEmailAddress } from "../../../../../views/common/legal";
import { pad } from "../../../../../shared/common/format";
import { CellValueType, RowType } from "../models/RowType";
import { OnboardingState } from "../../../common/models/onboardingState";
import { DateTime } from "luxon";

export interface DropdownOption {
    label: string;
    value: string;
    ref: number;
}

export const isValid = (field: string, value: CellValueType, collectiveAgreementOptions?: DropdownOption[]): boolean => {
    switch (field) {
        case "firstName":
        case "lastName":
            return isValidString(value as string);
        case "email":
            return isValidEmailAddress(value as string);
        case "hours":
            return isValidTime(value as string);
        case "startDate":
            return isValidDate(value as DateTime);
        case "collectiveAgreement":
            return isValidDropdownOption(collectiveAgreementOptions ?? [], value as string);
        case "delete":
            return false;
        default:
            return true;
    }
};

export const isValidTime = (time: string | undefined): boolean => {
    if (isValidString(time)) {
        const duration = serializationStringToDuration(time as string);

        return duration !== undefined &&
            duration !== null &&
            isValidPositiveInteger(duration.days) &&
            isValidPositiveInteger(duration.hours) &&
            isValidPositiveInteger(duration.minutes) &&
            duration.hours <= 23 &&
            duration.minutes <= 59;
    }
    return false;
};

function isValidPositiveInteger(input: number | undefined): boolean {
    return input !== undefined && input !== null && !isNaN(input) && input >= 0;
}


export const isValidDropdownOption = (options: DropdownOption[], value: string | undefined): boolean => {
    return options.some(option => option.value === value);
};

export const isValidString = (str: string | undefined): boolean => {
    return str !== undefined &&
        str !== null &&
        str.match(/^ *$/) === null;
};

export function isValidDate(input: DateTime | undefined): boolean {
    return input !== undefined &&
        input !== null &&
        typeof (input) !== "string" &&
        input.isValid;
}

export const filterRowsIntoStaff = (rows: RowType[], fields: string[], collectiveAgreementOptions: DropdownOption[]): OnboardingState["staff"] => {
    return rows
        .filter(row => rowIsValid(row, rows, fields, collectiveAgreementOptions))
        .map(row => rowToStaff(row, fields, collectiveAgreementOptions));
};

const rowIsValid = (row: RowType, rows: RowType[], fields: string[], collectiveAgreementOptions: DropdownOption[]): boolean => {
    const allFieldsAreValid = fields
        .every(field => isOptional(field, row[field]) || isValid(field, row[field], collectiveAgreementOptions));

    return allFieldsAreValid && noDuplicateEmail(row.email, row.id, rows);
};

const isOptional = (field: string, value: CellValueType): boolean => {
    return field === "email" && value === undefined;
};

const rowToStaff = (row: RowType, fields: string[], collectiveAgreementOptions: DropdownOption[]): OnboardingState["staff"][0] => {
    return {
        firstName: row.firstName as string,
        lastName: row.lastName as string,
        email: isValidEmailAddress(row.email) ?
            row.email : undefined,
        workHours: fields.some(field => field === "hours") ?
            row.hours : undefined,
        startDate: fields.some(field => field === "startDate") ?
            row.startDate : undefined,
        collectiveAgreementRef: fields.some(field => field === "collectiveAgreement") ?
            collectiveAgreementOptions.find(option => option.value === row.collectiveAgreement)?.ref :
            collectiveAgreementOptions[0].ref
    };
};

export const getNextRowId = (rows: { id: number }[]): number => {
    if (rows.length === 0) {
        return 0;
    } else {
        const elementWithHighestId = rows.reduce((prev, current) => (prev.id > current.id) ? prev : current);
        return elementWithHighestId.id + 1;
    }
};

type classNameEvaluator = ((params: GridCellParams<CellValueType, RowType>) => string);

export const generateInvalidCellHighlight = (rows: RowType[], fields: string[], selection: GridSelectionModel, collectiveAgreementOptions?: DropdownOption[]): classNameEvaluator =>
    (params) => {
        const rowIsSelected = selection.some(rowId => rowId === params.row.id);
        const rowIsBlank = isBlankRow(params.row, fields);
        const cellValueIsValid = isValid(params.field, params.value, collectiveAgreementOptions);
        const cellValueIsOptional = isOptional(params.field, params.value);
        const notEmail = params.field !== "email";
        const emailIsNotDuplicated = noDuplicateEmail(params.value as string, params.row.id, rows);

        let cellClassName = "must-fill-out";

        if (rowIsSelected ||
            rowIsBlank ||
            cellValueIsOptional ||
            (cellValueIsValid && (notEmail || emailIsNotDuplicated))
        ) {
            cellClassName = "";
        }

        return cellClassName;
    };


function noDuplicateEmail(email: string | undefined, id: number, rows: RowType[]): boolean {
    return rows
        .filter(row => isValidEmailAddress(row.email))
        .every(row => row.id === id || row.email !== email);
}

function isBlankRow(row: RowType, fields: string[]) {
    return fields
        .every(field => row[field] === undefined);
}

export function hasNoIncompleteRows(rows: RowType[], fields: string[], collectiveAgreementOptions: DropdownOption[]): boolean {
    return rows.every(row =>
        rowIsValid(row, rows, fields, collectiveAgreementOptions) ||
        isBlankRow(row, fields));
}

export interface Duration {
    days: number;
    hours: number;
    minutes: number;
}

export function normalizeDuration(duration: Duration): Duration {
    const totalHours = (duration.hours + Math.floor(duration.minutes / 60));
    return {
        days: duration.days + Math.floor(totalHours / 24),
        hours: totalHours % 24,
        minutes: duration.minutes % 60
    };
}

export function durationToDisplayString(duration: Duration): string {
    return `${pad(duration.days * 24 + duration.hours, 2)}:${pad(duration.minutes, 2)}`;
}

export function displayStringToDuration(text: string): Duration {
    const [totalHours, minutes] = text
        .split(":")
        .map(parseNumberSafe);

    return { days: Math.floor(totalHours / 24), hours: totalHours % 24, minutes: minutes };
}

export function durationToSerializationString(duration: Duration): string {
    return `${duration.days}.${pad(duration.hours, 2)}:${pad(duration.minutes, 2)}`;
}

export function serializationStringToDuration(text: string): Duration {
    const [daysAndHours, minutes] = text.split(":");
    const [days, hours] = daysAndHours.split(".");

    return {
        days: parseNumberSafe(days),
        hours: parseNumberSafe(hours),
        minutes: parseNumberSafe(minutes)
    };
}

export function parseNumberSafe(text: string): number {
    const number = parseInt(text);
    return isNaN(number) ? 0 : number;
}
