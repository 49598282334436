import { defineMessages } from "react-intl";

export const ApiKeysTable = defineMessages({
    Value: {
        id: "ApiKeysTable.Value",
        defaultMessage: "Value"
    },
    ValueExplanation: {
        id: "ApiKeysTable.ValueExplanation",
        defaultMessage: "API key values cannot be viewed except for immediately after creation. Be sure to save the secret when created before leaving the page."
    },
    Status: {
        id: "ApiKeysTable.Status",
        defaultMessage: "Status"
    },
    CreatedAt: {
        id: "ApiKeysTable.CreatedAt",
        defaultMessage: "Created at"
    },
    ExpiresAt: {
        id: "ApiKeysTable.ExpiresAt",
        defaultMessage: "Expires at"
    },
    LastUsage: {
        id: "ApiKeysTable.LastUsage",
        defaultMessage: "Last usage"
    },
    Revoke: {
        id: "ApiKeysTable.Revoke",
        defaultMessage: "Revoke"
    },
    NoKeys: {
        id: "ApiKeysTable.NoKeys",
        defaultMessage: "No keys have been created"
    },
    ExpirationTime: {
        id: "ApiKeysTable.ExpirationTime",
        defaultMessage: "Expiration time"
    },
    ExpirationMonths: {
        id: "ApiKeysTable.ExpirationMonths",
        defaultMessage: "{months} months"
    }
} as const);

export const ApiKeyStatus = defineMessages({
    Active: {
        id: "ApiKeyStatus.Active",
        defaultMessage: "Active"
    },
    Revoked: {
        id: "ApiKeyStatus.Revoked",
        defaultMessage: "Revoked"
    },
    Expired: {
        id: "ApiKeyStatus.Expired",
        defaultMessage: "Expired"
    }
} as const);