import { Typography } from "@mui/material";
import React, { CSSProperties, ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { FormattedMessageBreak } from "tp/views/common/components/formattedMessage/FormattedMessageBreak";
import { LoadingAndDone } from "tp/shared/common/components/loading/LoadingAndDone";
import { Common } from "tp/views/common/messages";
import { closeWizard } from "../../actions";
import { useDigSigLogSelector } from "../../redux";
import { wizardIntl } from "../DigitalSigningWizard";
import { useAsyncEffect } from "tp/shared/common/react-helper";
import { digitalSigningContractRefSendPost } from "tp/shared/timeplan-api-client";
import { Schemas } from "tp/shared/timeplan-api-schema";
import { ConfirmButton } from "tp/shared/common/components/buttons/ActionButtons";
import { marginXl } from "tp/shared/common-design/margins";

const DoneMessage = () => <FormattedMessageBreak id={wizardIntl("SendDone")} />;
const ErrorMesage = () => <FormattedMessageBreak id={wizardIntl("SendError")} />;
const ProcessingMessage = () => (
    <>
        <FormattedMessage id={wizardIntl("SendProcessing")} />
        <br />
        <p style={{ visibility: "hidden" }}>filler</p>{/* To ensure the height always stays the same */}
    </>
);

async function convertToBase64(file: File) {
    return new Promise<string>(resolve => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = event.target.result as string;
            resolve(data.substr(data.indexOf("base64,") + "base64,".length));
        };

        reader.readAsDataURL(file);
    });
}

export function StepConfirmation(): ReactElement {
    const {
        contractRef,
        employeeInfo,
        employerInfo,
        contractInput,
        pdfAttachment,
        signingMethod
    } = useDigSigLogSelector(state => state.digitalSigningLog);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();

    useAsyncEffect(async () => {
        const contractData: Schemas["SendForSigningCommand"] = {
            signingMethod,
            employee: employeeInfo,
            employer: employerInfo,
            contractInput,
            attachments: pdfAttachment && [{
                filename: pdfAttachment.name,
                contentType: pdfAttachment.type,
                base64Content: await convertToBase64(pdfAttachment)
            }]
        };

        try {
            await digitalSigningContractRefSendPost(contractRef, contractData);
            setDone(true);
        } catch (e) {
            setError(true);
        }
    }, []);

    const centerDiv = {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties;

    const centerContent = {
        width: "28rem",
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    } as CSSProperties;

    const confirmButtonStyle = {
        visibility: done ? "visible" : "hidden",
        marginTop: marginXl,
    } as CSSProperties;

    return (
        <div style={centerDiv}>
            <div style={centerContent}>
                <LoadingAndDone done={done} error={error} />
                <Typography style={{ textAlign: "center" }} variant="subtitle1">
                    {done ? <DoneMessage /> : error ? <ErrorMesage /> : <ProcessingMessage />}
                </Typography>
                <ConfirmButton style={confirmButtonStyle} onClick={() => dispatch(closeWizard())}>
                    <FormattedMessage {...Common.close} />
                </ConfirmButton>
            </div>
        </div>
    );
}