
import { pad } from "../../shared/common/format";

// inspired by https://github.com/rickard2/luhnarjs/blob/master/src/validator/sweden.js
export function validatePersonnummer(input: string): boolean {
    input = input.replace("-", "").replace("+", "");

    // Remove century and check number
    if (input.length === 12) {
        input = input.substr(2, 10);
    } else if (input.length === 10) {
        input = input.substr(0, 10);
    } else {
        return false;
    }

    const year = parseInt(input.substr(0, 2), 10);
    const month = parseInt(input.substr(2, 2), 10) - 1;
    const day = parseInt(input.substr(4, 2), 10);

    const date = new Date(year, month, day);

    const zeroPadYear = pad(year, 2);

    const validYear = date.getFullYear() === parseInt(`19${zeroPadYear}`) || date.getFullYear() === parseInt(`20${zeroPadYear}`);

    if (!validYear || date.getMonth() !== month || date.getDate() !== day) {
        return false;
    }

    // Remove check number
    const check = parseInt(input.substr(9, 1), 10);
    input = input.substr(0, 9);

    let result = 0;

    // Calculate check number
    for (let i = 0, len = input.length; i < len; i++) {

        let number = parseInt(input.substr(i, 1), 10);

        // Multiply every other number with two
        if ((i % 2) === 0) {
            number = (number * 2);
        }

        // If result is greater than 10, 'sum the digits'
        // which is the same as 1 + (number mod 10)
        if (number > 9) {
            result += (1 + (number % 10));
        } else {
            result += number;
        }
    }

    return (((check + result) % 10) === 0);
}

export const isValidEmailAddress = (email: string): boolean => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};
