import React, { ReactElement, ReactNode, useCallback, useEffect } from "react";
import {
    DataGrid,
    GridColDef,
    GridRowId,
    GridSelectionModel,
} from "@mui/x-data-grid";
import DeleteRowIcon from "./custom-ui/DeleteRowIcon";
import { renderCustomViewCell } from "./custom-ui/CustomViewCell";
import CustomEditCell from "./custom-ui/CustomEditCell";
import useExcelPaste from "./util/useExcelPaste";
import { setStaffList } from "../../common/models/actions";
import { useOnboardingSelector } from "../../common/models/redux";
import { useDispatch, useSelector } from "react-redux";
import {
    generateInvalidCellHighlight,
    filterRowsIntoStaff,
    getNextRowId,
    hasNoIncompleteRows,
    isValidString,
    isValidDropdownOption,
    isValidTime,
    isValidDate,
    serializationStringToDuration,
    DropdownOption,
    durationToDisplayString
} from "./util/helperFunctions";
import { StaffTableMessages } from "tp/site-admin/startpage/messages";
import { useIntl } from "react-intl";
import { Black, Gray1, TPRed_Light } from "tp/shared/common-design/colors";
import { RootState } from "tp/views/common/store";
import { sum } from "tp/shared/common/linq";
import { isValidEmailAddress } from "tp/views/common/legal";
import { CurrentUser } from "tp/shared/common/reducers/runtime";
import { RowType } from "./models/RowType";
import { ClickAwayListener } from "@mui/material";
import { dateToString } from "tp/shared/common/format";
import { DateTime } from "luxon";
import { AddButton } from "tp/shared/common/components/buttons/ActionButtons";

function makeDefaultRow(id: number, collectiveAgreementOptions: DropdownOption[]): RowType {
    return {
        id: id,
        hours: "00:00",
        startDate: DateTime.now(),
        collectiveAgreement: collectiveAgreementOptions[0].value,
    };
}

function getInitialRows(currentUser: CurrentUser, existingStaff: RowType[], collectiveAgreementOptions: DropdownOption[]): RowType[] {
    const initialRowsAlreadyCreated = existingStaff.length != 0 || !Object.isExtensible(existingStaff);
    if (initialRowsAlreadyCreated) return existingStaff;

    if (!currentUser) return [{
        ...makeDefaultRow(1, collectiveAgreementOptions)
    }];

    const email = currentUser.userName.trim();
    const fullName = currentUser.fullName.trim();
    const names = fullName.split(" ");

    const moreThanOneName = names.length >= 2;
    const firstName = moreThanOneName ? names.slice(0, -1).join(" ") : fullName;
    const lastName = moreThanOneName ? names.slice(-1)[0] : undefined;

    return [{
        ...makeDefaultRow(1, collectiveAgreementOptions),
        email: isValidEmailAddress(email) ? email : undefined,
        firstName: firstName,
        lastName: lastName
    }];
}

type StaffTableProps = {
    setCanContinue: (canMoveNext: boolean) => void,
    fields: string[],
    currentUser?: CurrentUser
};

export function StaffTable(props: StaffTableProps): ReactElement {
    const { setCanContinue, fields, currentUser } = props;
    const intl = useIntl();

    const shortDateFormat = useSelector((state: RootState) => state.runtime.shortDateFormat);

    const collectiveAgreementOptions =
        (useOnboardingSelector(state => state?.collectiveAgreementOptions) ?? [])
            .map(collectiveAgreement => ({
                label: collectiveAgreement.collectiveAgreementName,
                value: collectiveAgreement.collectiveAgreementName,
                ref: collectiveAgreement.collectiveAgreementRef,
            }));

    if (collectiveAgreementOptions.length === 0) {
        throw new Error("Collective agreement options were unable to be fetched");
    }

    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const handleSelectionModelChange = (newSelectionModel) => setSelectionModel(newSelectionModel);
    const handleClickAway = (event) => {
        const element = event.target as HTMLElement;

        const clickedDropdownOrCalendar =
            !!element.closest(".MuiList-root") ||
            !!element.closest(".MuiCalendarOrClockPicker-root");
        if (clickedDropdownOrCalendar) return;

        setSelectionModel([]);
    };

    const dispatch = useDispatch();
    const refreshStaff = (rows: RowType[]) => {
        const staff = filterRowsIntoStaff(rows, fields, collectiveAgreementOptions);

        setCanContinue(staff?.length > 0 && hasNoIncompleteRows(rows, fields, collectiveAgreementOptions));

        dispatch(setStaffList(staff, rows));
    };

    const unfilteredStaff = useOnboardingSelector(state => state.unfilteredStaff);

    const initialRows = getInitialRows(currentUser, unfilteredStaff, collectiveAgreementOptions);
    const [rows, setRows, onCellValueChange] = useExcelPaste(initialRows, fields, refreshStaff);

    useEffect(() => {
        refreshStaff(rows);
    }, [setCanContinue, rows]);

    const columns: GridColDef[] = fields
        .map<GridColDef>(field => ({
            field: field,
            editable: true,
            sortable: false,
            cellClassName: generateInvalidCellHighlight(rows, fields, selectionModel, collectiveAgreementOptions),
            headerAlign: "left",
            // eslint-disable-next-line react/display-name
            renderEditCell: (params) =>
                <CustomEditCell
                    setRows={setRows}
                    rows={rows}
                    fields={fields}
                    onChange={onCellValueChange}
                    {...params}
                />,
        }))
        .map<GridColDef>(col => {
            switch (col.field) {
                case "firstName":
                    return ({
                        ...col,
                        width: 100,
                        headerName: intl.formatMessage(StaffTableMessages.FirstName) + "*",
                        renderCell: renderCustomViewCell("Annika", (value) => isValidString(value as string | undefined)),
                    });
                case "lastName":
                    return ({
                        ...col,
                        width: 150,
                        headerName: intl.formatMessage(StaffTableMessages.LastName) + "*",
                        renderCell: renderCustomViewCell("Johansson", (value) => isValidString(value as string | undefined)),
                    });
                case "email":
                    return ({
                        ...col,
                        width: 400,
                        headerName: intl.formatMessage(StaffTableMessages.Email),
                        renderCell: renderCustomViewCell("annika.johansson@email.com", (value) => isValidEmailAddress(value as string | undefined)),
                    });
                case "hours":
                    return ({
                        ...col,
                        width: 150,
                        headerName: intl.formatMessage(StaffTableMessages.HoursPerWeek) + "*",
                        renderCell: renderCustomViewCell("00:00", (value) => isValidTime(value as string | undefined)),

                        align: "left",
                        valueFormatter: (params) => {
                            return params.value !== undefined ?
                                durationToDisplayString(serializationStringToDuration(params.value)) :
                                undefined;
                        }
                    });
                case "startDate":
                    return ({
                        ...col,
                        width: 150,
                        headerName: intl.formatMessage(StaffTableMessages.StartDate) + "*",
                        renderCell: renderCustomViewCell(
                            dateToString(DateTime.now(), shortDateFormat),
                            (value) => isValidDate(value as DateTime | undefined)),

                        type: "date",
                        valueFormatter: (params) => {
                            return params.value !== undefined ?
                                dateToString(params.value, shortDateFormat) :
                                undefined;
                        }
                    });
                case "collectiveAgreement":
                default:
                    return ({
                        ...col,
                        width: 200,
                        headerName: intl.formatMessage(StaffTableMessages.CollectiveAgreement) + "*",
                        renderCell: renderCustomViewCell(
                            intl.formatMessage(StaffTableMessages.CollectiveAgreementPlaceholder),
                            (value) => isValidDropdownOption(collectiveAgreementOptions, value as string | undefined)),

                        type: "singleSelect",
                        valueOptions: collectiveAgreementOptions,
                    });
            }
        })
        .concat({
            field: "delete",
            sortable: false,
            width: 50,
            headerName: "",
            cellClassName: "deleteIconCell",
            // eslint-disable-next-line react/display-name
            renderCell: (params): ReactNode =>
                <DeleteRowIcon
                    onClick={() => onDeleteRow(params.row.id)}
                />,
            headerAlign: "left"
        });

    const onDeleteRow = useCallback(
        (id: GridRowId) => {
            const lessRows = rows.filter(row => row.id !== id);

            setTimeout(() => {
                setRows(lessRows);
            });
        },
        [rows]
    );

    const onAddRow = useCallback(
        () => {
            const moreRows = rows.concat(
                makeDefaultRow(getNextRowId(rows), collectiveAgreementOptions)
            );
            setRows(moreRows);
        },
        [rows]
    );

    const processRowUpdate = React.useCallback(
        (updatedRow) => {
            const updatedRows = rows
                .map((row) => row.id === updatedRow.id ? updatedRow : row);

            setRows(updatedRows);
            return updatedRow;
        },
        [rows],
    );

    const noRowsOverlay = () => (
        <div style={{ display: "flex", justifyContent: "center", marginTop: 36 }} >
            <p>
                {intl.formatMessage(StaffTableMessages.NoRowsPromt)}
            </p>
        </div>
    );

    return (
        <div style={{
            width: sum(columns, col => col.width ?? 0),
            marginTop: 36,
            minHeight: "40vh",
        }}>
            <ClickAwayListener onClickAway={handleClickAway}>
                <DataGrid
                    style={{
                        marginBottom: 24,
                    }}

                    sx={{
                        ".MuiDataGrid-columnSeparator": {
                            display: "none"
                        },
                        "&.MuiDataGrid-root": {
                            border: "none",
                            color: Black
                        },
                        "& .MuiDataGrid-cell--editable": {
                            border: "1px solid " + Gray1,
                            fontStyle: "normal"
                        },
                        "&.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
                            borderBottomColor: Gray1
                        },
                        "& .MuiDataGrid-row.Mui-selected": {
                            backgroundColor: "unset"
                        },
                        "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                            padding: "0px"
                        },
                        "& .must-fill-out": {
                            backgroundColor: TPRed_Light,
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontStyle: "normal",
                            fontWeight: "bold",
                        },
                        "& .MuiDataGrid-columnHeader:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            borderBottom: "none"
                        },
                        "& .deleteIconCell": {
                            border: "none",
                            justifyContent: "flex-end",
                            padding: 0
                        },
                        "& .deleteIconCell:focus": {
                            outline: "none"
                        }
                    }}

                    components={{
                        NoRowsOverlay: noRowsOverlay,
                    }}

                    autoHeight

                    columns={columns}
                    rows={rows}

                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={(error) => { throw new Error(error); }}

                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelectionModelChange}

                    disableColumnMenu={true}
                    hideFooter={true}
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </ClickAwayListener>
            <div style={{ display: "flex", justifyContent: "center" }} >
                <AddButton onClick={onAddRow} />
            </div>
        </div>
    );
}
