import React, { ReactElement, useRef, useState } from "react";
import { Alert, Box, Button, Checkbox, CircularProgress, DialogContent, FormControlLabel, IconButton, List, ListItem, Paper, Stack, Switch, Typography } from "@mui/material";
import { marginLg, marginSm } from "tp/shared/common-design/margins";
import { ActionPopover, ActionPopoverProps } from "../ActionPopover";
import AddIcon from "@mui/icons-material/Add";
import * as Icons from "tp/shared/common-design/icons";

import { SplitButton } from "tp/shared/common/components/buttons/SplitButton";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "tp/views/common/store";
import { Task, TaskForm } from "./TaskForm";
import { Common as CommonMessages, Messages } from "tp/views/common/messages";
import { AddShift as AddShiftMessages } from "../../../messages";
import { dateToString } from "tp/shared/common/format";
import { DayTaskTemplateListProps, DayTaskTemplateList } from "../../layout/DayTaskTemplateList";
import { useAsyncEffect } from "tp/shared/common/react-helper";
import { components } from "tp/shared/timeplan-api-schema";
import { DateTime } from "luxon";
import { Common } from "tp/shared/common/messages";
import { Gray0, Gray1, TPTurquoiseGray } from "tp/shared/common-design/colors";
import { MaxLengthTextField } from "../../inputs/MaxLengthTextField";
import PrimaryButton from "tp/shared/common/components/buttons/PrimaryButton";
import { CancelButton } from "tp/shared/common/components/buttons/ActionButtons";

export type NameRef = { name: string, ref: number };
export type AddShiftData = components["schemas"]["ShiftDialog.Result"];

type OnSavedData = {
    dayTaskDate: Date,
    personRef?: number,
    templateName?: string,
    tasks: Task[]
};

export type DeleteResponse = {
    status: number,
    title: string,
    detail: string,
};

export type AddShiftPopoverProps =
    Pick<ActionPopoverProps, "open" | "anchorEl" | "onClose">
    & {
        dataLoader: () => Promise<AddShiftData>,
        date: Date,
        person?: NameRef,
        onSaved: (data: OnSavedData) => Promise<void> | void,
        onTemplateAdd: (dayTaskTemplateRef: number) => void,
        onTemplateDelete: (dayTaskTemplateRef: number) => Promise<unknown>,
    };

let globalTaskCounter = 0;

export function AddShiftPopover(props: AddShiftPopoverProps): ReactElement {
    const { dataLoader, date, person, onSaved, anchorEl, onTemplateAdd, onTemplateDelete, } = props;

    const dialogRef = useRef(null);

    const [useBreakRules, setUseBreakRules] = useState(true);
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    const [data, setData] = useState<AddShiftData | null>(null);
    const [tasks, setTasks] = useState<Record<number, Task | null>>({});
    const [saveAsTemplateOpen, setSaveAsTemplateOpen] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [saveState, setSaveState] = useState<"none" | "inProgress" | "error">("none");

    // Workaround for https://github.com/mui/material-ui/issues/28404
    const [tokenState, setTokenState] = useState(false);
    const forceRedraw = () => {
        setTokenState(!tokenState);
    };


    const shortDateFormat = useSelector((state: RootState) => state.runtime.shortDateFormat);

    useAsyncEffect(async () => {
        setData(null);
        setTasks({});
        const data = await dataLoader();
        setData(data);
        setUseBreakRules(data.breakRulesEnabled);
        setTemplateName(data.suggestedTemplateName);
    }, [dataLoader]);

    const updateTask = (key: string, task: Task | null) => {
        setTasks({ ...tasks, [key]: task });
    };

    const removeTask = (key: string) => {
        const result = { ...tasks };
        delete result[key];

        setTasks(result);
    };

    const addNewTask = () => setTasks({ ...tasks, [globalTaskCounter++]: null });

    const addNewTaskFromTemplate: DayTaskTemplateListProps["onAdd"] = (dayTaskTemplate) => onTemplateAdd(dayTaskTemplate.ref);

    const handleTemplateDelete: DayTaskTemplateListProps["onDelete"] = (ref) => onTemplateDelete(ref).then(
        (responseBody: DeleteResponse) => {
            if (responseBody != undefined && responseBody.status !== 200) return responseBody;

            setData({ ...data, dayTaskTemplates: data.dayTaskTemplates.filter((t) => t.ref !== ref) });
        }
    );

    const handleSaveAsTemplate = () => setSaveAsTemplateOpen(true);

    const handleCloseSaveAsTemplate = () => setSaveAsTemplateOpen(false);

    const handleSave = async (saveAsTemplate: boolean) => {
        setSaveState("inProgress");
        setSaveAsTemplateOpen(false);

        try {
            await onSaved({
                dayTaskDate: date,
                personRef: person?.ref,
                templateName: saveAsTemplate ? templateName : null,
                tasks: Object.values(tasks)
            });
            setSaveState("none");
        }
        catch {
            setSaveState("error");
        }
    };

    useAsyncEffect(async () => {
        setData(null);
        const data = await dataLoader();
        setData(data);

        setUseBreakRules(data.breakRulesEnabled);
        setTemplateName(data.suggestedTemplateName);

        if (data.dayTaskTemplates.length === 0) {
            setTasks({ [globalTaskCounter++]: null });
        }
    }, [dataLoader]);

    const tasksVisible = data && Object.entries(tasks).length > 0;

    const saveAsTemplateDialog = (
        <ActionPopover
            open={saveAsTemplateOpen}
            anchorEl={dialogRef.current}
            anchorOrigin={{ vertical: "center", horizontal: "center" }}
            transformOrigin={{ vertical: "center", horizontal: "center" }}
            actions={[
                <CancelButton key="cancel" variant="outlined" startIcon={<Icons.Cancel />} onClick={handleCloseSaveAsTemplate}><FormattedMessage {...Common.Cancel} /></CancelButton>,
                <PrimaryButton key="save" startIcon={<Icons.CheckCircle />} onClick={() => handleSave(true)} disabled={!templateName}><FormattedMessage {...CommonMessages.save} /></PrimaryButton>]}
            titleElement={<Typography><FormattedMessage {...AddShiftMessages.SaveAsTemplate} /></Typography>}
            onClose={handleCloseSaveAsTemplate}>
            <DialogContent>
                <MaxLengthTextField maxLength={10} size="small" fullWidth label={<FormattedMessage {...AddShiftMessages.TemplateName} />} value={templateName} onChange={e => setTemplateName(e.target.value)} />
            </DialogContent>
        </ActionPopover>);

    const title = (
        <Stack direction="row">
            <AddIcon />
            <Typography>
                <FormattedMessage {...AddShiftMessages.Title} values={{ date: dateToString(DateTime.fromJSDate(date), shortDateFormat) }} />
                {person && `, ${person.name}`}
            </Typography>
        </Stack>
    );

    const splitButton = (
        <SplitButton
            key="save"
            startIcon={saveState === "inProgress" ? <CircularProgress size={20} /> : <Icons.CheckCircle />}
            options={[
                { value: <FormattedMessage {...CommonMessages.add} />, onClick: () => handleSave(false) },
                { value: <FormattedMessage {...AddShiftMessages.AddAndCreateTemplate} />, onClick: handleSaveAsTemplate }]}
            disabled={Object.values(tasks).some(t => !t) || saveState === "inProgress"} />
    );

    const dayTaskTemplatesExists = data?.dayTaskTemplates.length > 0;

    const taskFormContainerHeight = { minHeight: tasksVisible ? "338px" : "60px" };
    const taskFormHeight = { minHeight: "120px", maxHeight: "calc(240px * 3.5)" };
    const taskTemplatesHeight = { maxHeight: `calc(240px * ${tasksVisible ? 2 : 4})` };

    return (
        <ActionPopover
            open={props.open}
            onClose={props.onClose}
            anchorEl={anchorEl}
            titleElement={title}
            PaperProps={{ sx: { display: "flex", flexDirection: "column", overflowY: "hidden", minWidth: "min(100% - 36px, 500px)" } }}>
            <Paper
                ref={dialogRef}
                elevation={3}
                sx={{
                    position: "relative",
                    zIndex: 1,
                    backgroundColor: (tasksVisible && (Object.keys(tasks).length % 2 === 0)) ? Gray1 : Gray0,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "hidden",
                    ...taskFormContainerHeight
                }}
            >
                {tasksVisible && <Stack paddingLeft={2} bgcolor={TPTurquoiseGray} direction="row" justifyContent="space-between">
                    <FormControlLabel
                        control={<Switch checked={useBreakRules} onChange={e => setUseBreakRules(e.target.checked)} />}
                        disabled={!data?.breakRulesEnabled}
                        label={(
                            <div>
                                <Typography variant="body2" sx={{ lineHeight: "normal" }}><FormattedMessage {...AddShiftMessages.UseBreakRules} /></Typography>
                                <Typography variant="body2" sx={{ fontSize: "11px", fontStyle: "italic", lineHeight: "normal" }}><FormattedMessage {...AddShiftMessages.UseBreakRulesSubtitle} /></Typography>
                            </div>
                        )} />
                    <FormControlLabel
                        control={<Checkbox sx={{ p: 0 }} disableRipple checked={showAdvancedSettings} onChange={() => setShowAdvancedSettings(!showAdvancedSettings)} icon={<Icons.ChevronRight />} checkedIcon={<Icons.ChevronLeft />} />}
                        label={
                            <Typography variant="body2" sx={{ lineHeight: "normal" }}>
                                {showAdvancedSettings ? <FormattedMessage {...AddShiftMessages.HideAdvancedSettings} /> : <FormattedMessage {...AddShiftMessages.AdvancedSettings} />}
                            </Typography>
                        } />
                </Stack>}
                {tasksVisible && <List disablePadding sx={{ overflowY: "auto", ...taskFormHeight }}>
                    {Object.keys(tasks).map((key, i) =>
                        <ListItem key={key} style={{ padding: marginLg, paddingTop: marginSm, paddingRight: 0, backgroundColor: i % 2 === 0 ? Gray0 : Gray1, alignItems: "flex-start" }}>
                            <TaskForm
                                showAdvancedSettings={showAdvancedSettings}
                                useBreakRules={useBreakRules}
                                onTaskUpdate={t => updateTask(key, t)}
                                breakIntervals={data?.breakIntervals}
                                hotels={data?.hotels}
                                snapSec={data?.snapSec}
                                collectiveAgreements={data?.collectiveAgreements}
                                taskTypes={data?.taskTypes}
                                useFoodBenefit={data?.useFoodBenefit}
                                otherTaskTimes={[
                                    ...(data?.otherTaskTimes ?? []).map(t => ({ ...t, isCurrentDay: false })),
                                    ...Object.entries(tasks)
                                        .filter(([k]) => key !== k)
                                        .filter(([, v]) => v !== null)
                                        .map(([, v]) => ({ fromSec: v.fromSec, untilSec: v.untilSec, isCurrentDay: true }))
                                ]}
                            />
                            <IconButton onClick={() => removeTask(key)} sx={{ top: "-8px", visibility: (dayTaskTemplatesExists || i !== 0) ? "visible" : "hidden" }}><Icons.Delete /></IconButton>
                        </ListItem>)}
                </List>}
                <Stack direction="row" justifyContent="space-between" alignItems={tasksVisible ? "flex-start" : "center"} sx={{ px: 2, pb: 2, pt: tasksVisible ? 1 : 2 }}>
                    <PrimaryButton
                        sx={{ lineHeight: "normal" }}
                        startIcon={<AddIcon />}
                        onClick={addNewTask}
                        disabled={!data}
                        variant="contained"
                        size="small"
                    >
                        {tasksVisible ? <FormattedMessage {...AddShiftMessages.AddTask} /> : <FormattedMessage {...AddShiftMessages.NewShift} />}
                    </PrimaryButton>
                    {tasksVisible && splitButton}
                </Stack>
                {saveState === "error" &&
                    <Alert severity="error">
                        <FormattedMessage {...Messages.GenericApiError} />
                    </Alert>}
                {saveAsTemplateDialog}
            </Paper>
            {dayTaskTemplatesExists &&
                <Box sx={{ padding: 2, overflowY: "auto", ...taskTemplatesHeight }}>
                    <Typography variant="h2" color="primary" sx={{ height: "26px" }}><FormattedMessage {...AddShiftMessages.SavedShifts} /></Typography>
                    <Box maxWidth="500px">
                        <DayTaskTemplateList
                            dayTaskTemplates={data.dayTaskTemplates}
                            onAdd={addNewTaskFromTemplate}
                            onDelete={handleTemplateDelete}
                            onAccordionChange={forceRedraw}
                            isDisabled={dtt => data.otherTaskTimes.some(t => dtt.taskTemplates.some(tt => (t.fromSec < tt.untilSec && t.untilSec > tt.fromSec)))} />
                    </Box>
                </Box>}
        </ActionPopover>
    );
}