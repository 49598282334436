import moment from "moment";
import { fn } from "moment";
import { JsonParser } from "./HttpClient";

// we would like an ISO8601 string that reflects the moment's utcOffset()
// src: https://momentjs.com/docs/#/displaying/as-json/
fn.toJSON = function() { return this.format(); };

export const momentReviver: Parameters<typeof JSON.parse>[1] = function(_, value) {
    if (typeof value === "string") {
        // avoid invalid parsing of simple numbers
        if (value.length > 12) {
            const m = moment(value, moment.ISO_8601, true);
            if (m.isValid()) {
                return m;
            }
        }
    }
    return value;
};

export const momentReviverUtc: Parameters<typeof JSON.parse>[1] = function(_, value) {
    if (typeof value === "string") {
        const m = moment.utc(value, moment.ISO_8601, true);
        if (m.isValid()) {
            return m;
        }
    }
    return value;
};

export const toJsonMoments: JsonParser = (response) => {
    return response.text().then((text) => {
        return JSON.parse(text, momentReviver);
    });
};
