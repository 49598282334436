import React from "react";
import { Delete as DeleteIcon } from "tp/shared/common-design/icons";
import { IconButton } from "@mui/material";

interface DeleteRowIconProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function DeleteRowIcon(props: DeleteRowIconProps): React.ReactElement {
    return (
        <IconButton tabIndex={-1} aria-label="delete" onClick={props.onClick} size="large">
            <DeleteIcon />
        </IconButton>
    );
}