
export enum LoadState {
    Idle = 0,
    Normal = 1,
    Slow = 2
}

export interface SessionData {
    collectiveAgreementRef?: number;
    personRef?: number;
    userRef?: number;
}
