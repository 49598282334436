import React, { ReactElement } from "react";
import { TextField, TextFieldProps } from "@mui/material";

export function MaxLengthTextField(props: TextFieldProps & { maxLength: number }): ReactElement {
    const { maxLength, inputProps, helperText, ...restProps } = props;

    return <TextField
        inputProps={{ maxLength: maxLength, ...(inputProps || {}) }}
        helperText={helperText || `${(props.value as string)?.length || 0}/${maxLength}`}
        {...restProps} />;
}