import { TypedUseSelectorHook, useSelector } from "react-redux";
import { Schemas } from "tp/shared/timeplan-api-schema";
import { LoadState } from "tp/views/common/models";
import { RootState } from "tp/views/common/store";
import { Pricing } from "./DigitalSigningLog";

export type DigitalSigningLogState = Schemas["SigningLogsAndStatus"] & {
    loadState: LoadState;
    // Gemensam
    contractRef: number;
    isWizardOpen: boolean;
    // Wizard
    wizardStep: number;
    // Step 1
    contractInput: Schemas["SendForSigningCommand"]["contractInput"];
    // Step 2 / Form
    employeeInfo: Schemas["SendForSigningCommand"]["employee"];
    employerInfo: Schemas["SendForSigningCommand"]["employer"];
    pdfAttachment: File;
    signingMethod: Schemas["SendForSigningCommand"]["signingMethod"];
    pricing: Pricing;
}

export type DigitalSigningLogCombinedState = RootState & { digitalSigningLog: DigitalSigningLogState };

export const useDigSigLogSelector: TypedUseSelectorHook<DigitalSigningLogCombinedState> = useSelector;