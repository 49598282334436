import React, { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { Button, ButtonProps, Icon } from "@mui/material";
import { Delete as DeleteIcon, CheckCircleRounded } from "tp/shared/common-design/icons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FormattedMessage } from "react-intl";
import { Gray1, lighterShade, TPBlue, TPRed, TPTurquoise_Light, White } from "../../../common-design/colors";
import { CommonElements, Common as Messages } from "../../../../views/common/messages";
import { Common } from "tp/shared/common/messages";
import PrimaryButton from "./PrimaryButton";
import { buttonBorderRadius } from "tp/views/common-design/borders";

export const OkButton = (props: ButtonProps): React.ReactElement => {
    return (
        <PrimaryButton
            {...props}>
            <FormattedMessage {...Messages.ok} />
        </PrimaryButton>
    );
};

export const CompletedButton = (props: ButtonProps): React.ReactElement => {
    return (
        <PrimaryButton
            startIcon={<CheckCircleRounded />}
            {...props}>
            <FormattedMessage {...Messages.completed} />
        </PrimaryButton>
    );
};

export const CancelButton = (props: ButtonProps): React.ReactElement => {
    return (
        <Button
            variant="outlined"
            {...props}>
            <FormattedMessage {...Common.Cancel} />
        </Button>
    );
};

const ButtonDeleteStyle = styled(Button)({
    color: White,
    backgroundColor: TPRed,
    "&:hover": {
        backgroundColor: lighterShade(TPRed, 90)
    }
});

export const DeleteButton = (props: ButtonProps): React.ReactElement => {
    return (
        <ButtonDeleteStyle
            variant="contained"
            startIcon={<DeleteIcon />}
            {...props}
        >
            <FormattedMessage {...Messages.delete} />
        </ButtonDeleteStyle>
    );
};

export function ConfirmButton(props: ButtonProps): ReactElement {
    const { style, ...buttonProps } = props;

    const buttonStyle = {
        borderRadius: buttonBorderRadius,
        height: "50px",
        width: "50%",
        fontSize: "18px"
    };
    const activeStyle = {
        backgroundColor: TPBlue,
        color: White
    };

    const disabledStyle = {
        backgroundColor: TPTurquoise_Light,
        color: Gray1,
    };

    const combinedStyle = {
        ...buttonStyle,
        ...(buttonProps.disabled ? disabledStyle : activeStyle),
        ...style
    };

    return (
        <PrimaryButton {...buttonProps} style={combinedStyle}>
            {props.children}
        </PrimaryButton>
    );
}

export function AddButton(props: ButtonProps): ReactElement {
    const { style, ...buttonProps } = props;

    const buttonStyle = {
        borderRadius: buttonBorderRadius,
        padding: "0.5em 1em",
    };

    const activeStyle = {
        backgroundColor: TPRed,
        color: "white"
    };

    const disabledStyle = {
        backgroundColor: TPRed,
        opacity: 0.6,
    };

    const combinedStyle = {
        ...buttonStyle,
        ...(buttonProps.disabled ? disabledStyle : activeStyle),
        ...style
    };

    return (
        <PrimaryButton
            startIcon={<AddCircleIcon />}
            style={combinedStyle}
            {...buttonProps}>
            <FormattedMessage {...Messages.add} />
        </PrimaryButton>
    );
}

interface NewButtonProps {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
}

export function NewButton(props: NewButtonProps): ReactElement {
    const { ...buttonProps } = props;

    const buttonStyle = {
        height: "26px",
        paddingLeft: "8px",
        paddingRight: "8px",
        marginTop: "4px",
        borderRadius: "8px"
    };
    const iconStyle = {
        fontSize: 16,
        marginRight: 8
    };

    return (
        <Button
            variant="contained"
            color="primary"
            {...buttonProps}
            style={buttonStyle}
        >
            <Icon style={iconStyle} className="fas fa-plus-circle" />
            {CommonElements.new}
        </Button>
    );
}
