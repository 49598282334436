import { unstable_useId as useId } from "@mui/material";
import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useReducer } from "react";

const FormContext = createContext<[value: object, setValue: (newValue: object) => void]>(null);

export const INPUT_CHANGED_MESSAGE_TYPE = "InputChanged";

export function useInputValue<T>(initialValue: T): [value: T, setValue: (newValue: T) => void] {
    const [state, dispatch] = useContext(FormContext);
    const id = useId();

    useEffect(() => {
        if (id && !Object.prototype.hasOwnProperty.call(state, id)) {
            dispatch({ [id]: initialValue });
        }
    }, [id, state, dispatch]);

    return id
        ? [state[id], v => dispatch({ [id]: v })]
        : [initialValue, () => { throw new Error("Can't call setter before ID is generated."); }];
}

const inputValuesReducer = (state, action) => {
    const newState = { ...state, ...action };
    if (window.parent !== window) {
        window.parent.postMessage({ messageType: INPUT_CHANGED_MESSAGE_TYPE, value: newState }, window.location.origin);
    }
    return newState;
};

export function FormContextProvider(props: { initialInput: object, children?: ReactNode }): ReactElement {
    const context = useReducer(inputValuesReducer, props.initialInput || {});
    return <FormContext.Provider value={context}>{props.children}</FormContext.Provider>;
}