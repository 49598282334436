import { Accordion, AccordionDetails, AccordionSummary, Icon, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { White } from "../../../../../shared/common-design/colors";

const AccordionExpandIcon = (props: { color: string }) => <Icon className="fas fa-chevron-circle-down" sx={{ color: props.color, p: 1.5, fontSize: "1.5rem !important" }} />;

export type CostDivisionAccordionProps = {
    title: string,
    color: string,
    children?: React.ReactElement[] | React.ReactElement,
    defaultExpanded?: boolean,
    onChange?: () => void,
};

export function CostDivisionAccordion(props: CostDivisionAccordionProps): ReactElement {
    const { title, color, children, defaultExpanded, onChange } = props;

    return <Accordion TransitionProps={{ onEntered: onChange, onExited: onChange }} disableGutters sx={{ backgroundColor: White }} defaultExpanded={defaultExpanded}>
        <AccordionSummary
            sx={{ paddingLeft: 0, flexDirection: "row-reverse" }}
            expandIcon={<AccordionExpandIcon color={color} />}>
            <Typography variant="body1">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
            {children}
        </AccordionDetails>
    </Accordion>;
}