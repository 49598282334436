import * as React from "react";
import { getUrls } from "tp/shared/common/reducers/runtime";
import { useDispatch, useSelector } from "react-redux";
import * as api from "tp/site-admin/startpage/services/api";
import { RouteComponentProps } from "react-router";
import { StaffTableStep } from "./StaffTableStep";
import { SendInviteStep } from "./SendInviteStep";
import { LocalizationProvider } from "tp/views/common/components";
import { getLocale } from "tp/shared/common/reducers/selectors";
import "tp/site-admin/startpage/styles.scss";
import "tp/views/common/components/header/header-style.scss";
import { Wizard } from "./Wizard";
import { setCollectiveAgreementOptions, setStaffTableFields } from "tp/site-admin/onboarding/common/models/actions";
import { OnboardingState } from "tp/site-admin/onboarding/common/models/onboardingState";
import { initialize } from "tp/shared/timeplan-api-client";

export interface AddPersonsProps extends RouteComponentProps {
    open: boolean;
    onClose: () => void;
    staffTableFields: string[];
    collectiveAgreements: OnboardingState["collectiveAgreementOptions"];
}

export const AddPersons = function (props: AddPersonsProps): React.ReactElement {
    const { open, onClose, staffTableFields, collectiveAgreements } = props;
    const locale = useSelector(getLocale);

    const urls = getUrls();
    api.initialize(urls);
    initialize(urls);

    const localizedData = {
        sv: { ...require("tp/site-admin/startpage/intl/sv.json") },
        en: { ...require("tp/site-admin/startpage/intl/en.json") }
    };

    const dispatch = useDispatch();
    dispatch(setCollectiveAgreementOptions(collectiveAgreements));
    dispatch(setStaffTableFields(staffTableFields));

    const onboardingSteps = [
        StaffTableStep,
        SendInviteStep
    ];

    return (
        <LocalizationProvider locale={locale} localizedMessages={localizedData}>
            <Wizard
                open={open}
                steps={onboardingSteps}
                onClose={onClose}
            />
        </LocalizationProvider>
    );
};
