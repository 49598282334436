import { defineMessages } from "react-intl";

export const AddPersonsMessages = defineMessages({
    StaffTableStepTitle: {
        id: "AddPersons.StaffTableStepTitle",
        defaultMessage: "Add new staff",
    },
    SubmitError: {
        id: "AddPersons.SubmitError",
        defaultMessage: "Something went wrong while submitting. Please contact support in case it happens again"
    }
} as const);

export const Pages = defineMessages({
    Relatives: {
        id: "Pages.Relatives",
        defaultMessage: "Staff - Relatives"
    }
} as const);

export const Relatives = defineMessages({
    Children: {
        id: "Relatives.Children",
        defaultMessage: "Children",
    },
    Relative: {
        id: "Relatives.Relative",
        defaultMessage: "Next of kin",
    },
    Name: {
        id: "Relatives.Name",
        defaultMessage: "Name",
    },
    Relation: {
        id: "Relatives.Relation",
        defaultMessage: "Relation",
    },
    Birthday: {
        id: "Relatives.Birthday",
        defaultMessage: "Birthdate",
    },
    Telephonenumber1: {
        id: "Relatives.Telephonenumber1",
        defaultMessage: "Phone 1",
    },
    Telephonenumber2: {
        id: "Relatives.Telephonenumber2",
        defaultMessage: "Phone 2",
    },
    Address: {
        id: "Relatives.Address",
        defaultMessage: "Address",
    },
    City: {
        id: "Relatives.City",
        defaultMessage: "City",
    },
    ZipCode: {
        id: "Relatives.ZipCode",
        defaultMessage: "Postcode",
    },
    Country: {
        id: "Relatives.Country",
        defaultMessage: "Country",
    },
    WarningMessage: {
        id: "Relatives.WarningMessage",
        defaultMessage:
            "Both name and birthdate should be filled in for each child. Birthdate should be in the format 'YYYY-MM-DD'. Character limit of 50 for all fields except phone number at 100.",
    },
    HasConnections: {
        id: "Relatives.HasConnections",
        defaultMessage: "This child is connected to other fields in Timeplan and can't be deleted.",
    },
} as const);