import Button, { ButtonProps } from "@mui/material/Button";
import React, { ReactElement } from "react";

export default function PrimaryButton(props: ButtonProps): ReactElement {

    return (
        <Button
            {...props}
            variant="contained"
        >
            {props.children}
        </Button>
    );
}
